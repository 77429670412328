.filter_panel {
  padding-bottom: 5px;
  display: flex;
  gap: 20px;
  position: sticky;
  top: 60px;
  z-index: 10;
  border-bottom: solid 1px rgb(218 218 218) !important;
}
.filter_search {
  padding: 27px 0px 0px 15px;
}
.filter_search_elementinput {
  display: inline-flex;
  position: relative;
  min-width: 220px;
  min-height: 33px;
  width: 100%;
  box-sizing: border-box;
  cursor: text;
}
.filter_search_elementinput input {
  padding-left: 10px;
  width: 100%;
  border: 1px solid var(--brand-color-select-border);
  border-radius: 5px;
}
.filter_search_elementinput input:hover {
  border: 1px solid var(--brand-color-hover-border);
}
.filter_search_elementinput input:focus {
  border: 1px solid var(--brand-color-hover-border);
}
.filter_search_elementinput svg {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: 4px;
}
.filter_item {
  width: 210px;
}
.filter_panel_selection {
  padding: 5px;
}
.filter_panel_tittle {
  padding-bottom: 5px;
  font-size: 0.75rem;
  line-height: 1.4375em;
  color: var(--brand-color-two-text);
}
.filter_panel_selectiontext_block {
  display: flex;
  position: relative;
  width: 100%;
  height: 31px;
  border: 1px solid var(--brand-color-select-border);
  border-radius: 5px;
}
.filter_panel_selectiontext_item {
  cursor: pointer;
  position: relative;
  width: 100%;
  justify-items: center;
  align-items: center;
  display: flex;
}
.filter_panel_selectiontext {
  width: 100%;
  padding: 5px;
}
.filter_panel_selectiontext_block:hover {
  border: 1px solid var(--brand-color-hover-border);
}
.icon_selector_filter_panel {
  position: absolute;
  right: 5px;
  top: 8px;
}
.opening_filter_select {
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  min-width: 190px;
  max-width: 230px;
  max-height: 400px;
  width: max-content;
  z-index: 25;
  position: absolute;
  top: 35px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}
.filter_selector_option   {
  padding-bottom: 15px;
  color: var(--brand-color-nine-text);
  cursor: pointer;
  position: relative;
  padding: 5px 1px;
  box-sizing: border-box;
  white-space: nowrap;
}
.filter_selector_option:hover {
  background-color: var(--brand-color-three-background);
}
.group_block .filter_selector_option{
  padding-left:10px;
}
.opening_filter_select .group_block{
  margin-bottom: 5px;
  margin-left: 4px;
  border-left: solid #c9c9c9 1px;
}
.filter_selector_option.active svg {
  color: var(--brand-color-nine-border);
}
.filter_selector_option svg {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  z-index: 30;
}
.icon_items__selector_value {
  display: flex;
  gap: 10px;
}
.selector_value {
  align-items: center;
  display: flex;
}

.opening_filter_select::-webkit-scrollbar {
  width: 7px; /* Ширина полосы прокрутки */
  height: 7px;
}

.opening_filter_select::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}

.opening_filter_select:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

@media (max-width: 576px) {
  .filter_panel {
    display: none;
  }
  .filter_panel.active {
    display: block;
    position: absolute;
    width: 100%;
    background-color: rgb(46, 48, 60);
  }
  .filter_panel .filter_search {
    padding: 10px;
  }
  .filter_panel .filter_item {
    width: 100%;
    padding: 10px;
  }
  .filter_panel_selectiontext_item {
    color: #fff;
  }
}
