.structure {
  width: max-content;
  min-width: 100%;
}
.structure-level-main {
  position: relative;
}
.structure-level-main:after {
  position: absolute;
  content: "";
  left: 50%;
  height: 1rem;
  bottom: -1rem;
  border-left: solid #000 1px;
}
.structure-level-one-items {
  max-width: max-content;
  margin: 0 auto;
  position: relative;
}
.structure-level-one-items:before {
  content: "";
  position: absolute;
  top: 0px;
  width: calc(100% - 252px);
  border-top: solid #000 1px;
  left: 126px;
}
.structure-level-one-item {
  width: 220px;
  position: relative;
}
.structure-level-one-item:before {
  position: absolute;
  content: "";
  left: 50%;
  height: 16px;
  border-left: solid #000 1px;
  top: -16px;
}

.structure-level-two-items {
  width: 210px;
  padding-left: 20px;
  position: relative;
}
.structure-level-two-items:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 10px;
  bottom: 25px;
  border-left: solid #000 1px;
}
.structure-level-two-item {
  width: 200px;
  padding-top: 20px;
  position: relative;
}
.structure-level-two-item:after {
  content: "";
  position: absolute;
  bottom: 25px;
  left: -10px;
  width: 10px;
  border-bottom: solid #000 1px;
}

.structure-change-the-section {
  position: absolute;
  right: 30px;
  top: 9px;
  z-index: 25;
}
.structure-add-section {
  position: absolute;
  right: 50px;
  top: 9px;
  z-index: 15;
}
.structure-add-section-main {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 15;
}
.structure-delete-section {
  position: absolute;
  right: 10px;
  top: 9px;
  z-index: 15;
}
.active_delete {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 25;
}
.active_delete_child {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 25;
}

.structure-department-name {
  padding-right: 70px;
}
.structure-department-name-main {
  padding-right: 70px;
}
.listdepartment-parent-block {
  border: 1px solid var(--c-three);
  background-color: var(--c-eight);
}
.listdepartment-main-block {
  border: 1px solid var(--c-three);
  background-color: var(--c-eight);
  position: relative;
}

.not_active_delete {
  color: var(--c-nine);
}
.not-staff {
  color: var(--c-nine);
}
.listdepartment-child-block {
  border: 1px solid var(--c-three);
  background-color: var(--c-eight);
  position: relative;
}
.structure-user {
  font-size: 12px;
}

.listdepartment {
  background-color: var(--brand-color-eight-text);
  overflow-y: scroll;
  max-width: 200px;
  max-height: 300px;
  color: var(--brand-color-one-text);
  font-size: 14px;
  border: 1px solid var(--brand-color-one-border);
  border-radius: 7px;
  padding: 5px 0px 5px 5px;
  width: max-content;
  position: absolute;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
  top: 35px;
  right: 5px;
}

.listdepartment-selector-value {
  padding: 5px;
  font-size: 12px;
  line-height: 14px;
}
.listdepartment-selector-value:hover {
  background-color: var(--brand-color-three-background);
  cursor: pointer;
  padding: 5px;
  border-radius: 7px;
}

.listdepartment::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
}
.listdepartment::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.listdepartment:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
