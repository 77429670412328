.registration {
  justify-items: center;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  background-size: cover !important;
  padding-top: 70px;
}
.registration_all_content {
  padding: 0px 190px;
}
.registration_block {
  padding: 15px 15px 60px;
  position: relative;
}
.registration_block_50 {
  width: 45%;
  min-width: 300px;
  max-width: 400px;
}
.registration_block_yes {
  padding: 15px 15px 25px;
  width: 45%;
  position: relative;
}
.registration_block_agreement a {
  text-decoration: underline;
  color: #1677ff;
}

.registration_block_tittle_area {
  padding: 0px 0px 20px 0px;
}
.registration_block_tittle {
  padding: 10px;
}
.registration_textarea_name,
.registration_textarea_email,
.registration_textarea_phone,
.registration_textarea_company {
  border: 1px solid rgb(230, 235, 241);
  cursor: text;
  border-radius: 4px;
  margin: 15px 0px;
}

.registration_textarea_name input:focus,
.registration_textarea_email input:focus,
.registration_textarea_phone input:focus,
.registration_textarea_company input:focus {
  border: 0px;
  outline: none;
}

.registration_textarea_name_send {
  padding: 15px;
}
.send_button_auth_registration {
  color: var(--brand-color-eight-text);
  cursor: pointer;
  background-color: var(--brand-color-one-button);
  border-radius: 5px;
  padding: 5px;
}

.registration_input_name,
.registration_inputemail,
.registration_input_phone,
.registration_input_company {
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  width: 100%;
  padding: 10.5px 14px 10.5px 12px;
}
.registration_send_button {
  background-color: var(--brand-color-one-button);
  border-radius: 5px;
  padding: 5px;
  color: var(--brand-color-eight-text);
  width: max-content;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 0px;
}
.registration_send_button_block {
  position: absolute;
  right: 20px;
  bottom: 15px;
}
.captcha-block-area {
  display: grid;
  grid-template-columns: 50% 50%;
}

.registration_send_button_block_yes {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.registration_logo_authblock {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.registration_logoblack__text_slogan {
  padding: 15px;
  max-height: 400px;
  width: 55%;
}
.registration_logoblack {
  display: flex;
  gap: 20px;
}
.registration_logoblack img {
  width: 250px;
  padding: 15px;
}
.registration_description_text {
  padding: 25px 0px;
}

.captcha-block-img img {
  max-width: 120px;
}
.inputValueCaptcha {
  max-width: 120px;
}

.btn-update-captcha {
  padding-top: 10px;
  cursor: pointer;
  border-bottom: 1px dashed var(--c-six);
  width: max-content;
}
.target-auth {
  position: absolute;
  right: 15px;
  top: 27px;
  text-decoration: underline;
  color: #1677ff;
}

.registration_email_is_error_text {
  color: #ff3838;
  font-size: 14px;
  padding: 3px 0px;
}

@media (max-width: 576px) {
  .registration_all_content {
    padding: 3px;
  }
  .registration_logoblack__text_slogan {
    display: none;
  }
  .registration_block_50 {
    width: 100%;
  }
  .registration {
    padding-top: 30px;
  }
}
.registration_input_block .captcharesult-text {
  color: #ff3838;
  font-size: 14px;
  padding: 3px 0px;
}

.registration_email_is_error {
  text-align: center;
}
.registration_email_is_error_target {
  text-decoration: underline;
  color: #1677ff;
}
