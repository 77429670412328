.title_h1__add_counterpartie {
  display: flex;
  position: relative;
}

.counterparties_table__counterparties_title_table {
  padding: 20px;
}
.counterparties_title_table {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1px 210px auto 170px 140px 170px 40px;
  align-items: center;
  padding: 13px 15px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.counterparties_grid_template {
  display: grid;
  grid-template-columns: 211px auto 170px 140px 170px 40px;
  align-items: center;
  padding: 13px 15px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.counterparties_arrpartie_item-block {
  display: flex;
  gap: 10px;
}
.counterparties_arrpartie_item-block {
  align-items: center;
  display: flex;
  gap: 20px;
}
.counterpartie_logo {
  height: 30px;
  width: 80px;
}
.counterparties_arrpartie_item img {
  border-radius: 4px;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  max-width: 100%;
}
.counterparties_no_logo {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 80px;
  height: 30px;
  background-color: rgb(230, 244, 255);
}

.counterparties_delete_button svg {
  color: var(--brand-color-three-svg);
}
