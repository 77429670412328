.tableticketsfast-grid-template {
  display: grid;
  grid-template-columns: 40px 40px auto 190px 130px 100px 150px 120px;
  min-height: 50px;
}
.tableticketsfast-grid-template > div {
  align-items: center;
  display: flex;
  padding: 10px 5px;
  border-bottom: solid 1px var(--c-twelve);
}
.tableticketsfast-users-executor {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;
}
.tableticketsfast-users-executor-item {
  border-bottom: 1px dashed #b2b2b2;
  padding: 5px 0px;
}
.tableticketsfast-items {
  height: calc(100% - 40px);
  min-height: 400px;
}
.tableticketsfast-item:hover {
  background-color: var(--c-two);
}
.tableticketsfast {
  overflow-x: scroll;
}
.quick-view {
  color: var(--c-thirteen);
}
.quick-view-description-fast {
  padding: 5px;
  color: rgb(178 178 178);
  font-size: 12px;
  line-height: 20px;
  word-wrap: break-word;
  width: auto;
  max-height: 50px;
  overflow: hidden;
  word-break: break-all;
}

.tableticketsfast::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 10px; /* Высота полосы прокрутки */
}

.tableticketsfast::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}

.tableticketsfast:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
.tableticketsfast-users-executor {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;
}
.tableticketsfast-users-executor-item {
  border-bottom: 1px dashed #b2b2b2;
}

@media (max-width: 576px) {
  .tableticketsfast-grid-template {
    grid-template-columns: 10px 50px 200px 200px 100px 100px;
  }
}
