.sidebar_menu {
  width: 200px;
  height: calc(100% - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  padding-top: 10px;
  background-color: var(--brand-color-one-background);
  z-index: 20;
}

.sidebar_menu_items {
  overflow-y: scroll;
  height: calc(100% - 80px);
  font-size: 13px;
}
.sub_sections_item {
  padding: 1px 1px 1px 10px;
}
.sub_sections_item:hover {
  background-color: var(--brand-color-two-background);
}
.section_text_tittle {
  padding: 15px 15px;
  color: var(--brand-color-two-text);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;
}
.sub_sections {
  color: var(--brand-color-one-text);
  position: relative;
}
.sub_section_icon__sub_section_text_title {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-width: 0px;
  box-sizing: border-box;
  text-align: left;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 3px 16px 3px 16px;
}
.sub_section_icon {
  flex-shrink: 0;
  display: inline-flex;
  min-width: 28px;
}
.sub_section_icon svg {
  width: 18px;
  height: 18px;
}

.sub_section_text_title {
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 5px;
  margin-bottom: 3px;
}
.app_logo_block {
  position: absolute;
  bottom: 0px;
  padding: 0px 15px 15px;
  background: var(--brand-color-one-background);
  background-color: var(--brand-color-one-background);
}

.sidebar_menu .app_logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 3px;
  gap: 10px;
  border-top: 1px solid var(--brand-color-three-line);
  border-bottom: 1px solid var(--brand-color-three-line);
}
.sidebar_menu .app_logo img {
  width: 35%;
}
.slogan {
  font-size: 13px;
  padding: 10px 10px 25px;
  text-align: center;
  color: var(--brand-color-two-text);
}
.copyright {
  text-align: center;
}
.copyright_text {
  font-size: 12px;
}
.made_in_rf {
  font-weight: 600;
  font-size: 13px;
}

.sidebar_menu_items::-webkit-scrollbar {
  width: 4px; /* Ширина полосы прокрутки */
  height: 10px; /* Высота полосы прокрутки */
}

.sidebar_menu_items::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}

.sidebar_menu_items:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
