.project-manager-image img {
  max-width: 100%;
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 0px 11px #00000029;
}

.counterpartie-image img {
  width: 160px;
  height: 60px;
  object-fit: contain;
}

.counterpartie-sticky {
  position: sticky;
  top: 70px;
}

.counterpartie .project-manager-name-block .a-text-4 {
  font-size: 0.7rem;
}

.a-text-1 .change-items-value {
  font-weight: 600;
  font-size: 1.4rem !important;
  line-height: 1.2;
  color: var(--c-eleven) !important;
}
.counterpartie .change-items-value {
  color: #8c8c8c;
}

.right-block-main {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.counterpartie .a-text-4 {
  color: var(--c-eleven);
}
.counterpartie-section-title .change-items-active {
  display: flex;
  position: relative;
  height: 31px;
}
.counterpartie-section-title .change-items-btn-save {
  position: relative;
  right: 0px;
}
.counterpartie-section-title .change-items-btn-save svg {
  position: absolute;
  top: 4px;
}
.left-block-bottom .change-items-active {
  display: flex;
  position: relative;
}

.counterpartie_image_label {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
  cursor: pointer;
}
.counterpartie_image_label svg {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.counterpartie_image_label:hover {
  opacity: 1;
}
