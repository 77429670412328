/*Main*/

.landing-ticket {
  position: relative;
}

.content_ticket_block_body {
  padding: 20px;
  margin-bottom: 15px;
}
.save_textarea__cancel_textarea {
  display: flex;
  gap: 20px;
  position: absolute;
  right: 17px;
}

textarea {
  border: 0px;
  outline: none;
}
.ticket_box_name__ticket_box_number {
  width: 100%;
  font-size: 18px;
}
.ticket_box_name {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
  min-height: 45px;
}
.textarea_subject {
  position: relative;
  min-height: unset;
  height: 30px;
}
.textarea_subject .save_textarea__cancel_textarea {
  position: absolute;
  top: 0px;
  right: 0px;
  gap: 10px;
}
.textarea_subject textarea {
  padding: 0px;
  border-bottom: solid #cbcbcb 1px !important;
}
.template_name_block {
  position: absolute;
  top: 7px;
  width: calc(80% - 140px);
  left: 120px;
}
.ticket_name_value {
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 20px;
  width: max-content;
  max-width: 100%;
}

.ticket_box_number .ticket-number {
  display: flex;
  gap: 5px;
  color: var(--brand-color-two-text);
  font-size: 14px;
}
.ticket-box-text {
  width: 100%;
  min-height: 120px;
  margin: 20px 0px 20px 0px;
  position: relative;
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 5px;
}
.ticket-box-text .addfile-block {
  padding: 0px;
}
.ticket-content-text {
  min-height: 145px;
}
.tisketmain_description_value {
  width: 95%;
}

.icon-ticket-description {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #7e7e7e;
  cursor: pointer;
  font-size: 12px;
}
.input_ticket_box_text textarea {
  width: 100%;
  border: none;
}
.textarea_subject textarea {
  width: calc(100% - 200px);
}
.template_name_block textarea {
  background: #f6f7fa;
}

.input_ticket_box_text.textarea_description textarea {
  min-height: 105px;
  font-size: 13px;
  background: #fff;
  border: 0px solid rgb(239 239 239);
  border-radius: 5px;
  padding: 5px 10px;
  outline: 1px solid rgb(239 239 239);
}

.input_ticket_box_text.textarea_description textarea:focus {
  outline: 1px solid rgb(239 239 239);
}
.save-cancel-textarea {
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: var(--brand-color-two-text);
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}
.addfile-block {
  padding: 7px 0px;
}
.input_ticket_box_text textarea:focus {
  max-width: 100% !important;
  border: none;
}
.save-cancel-textarea-block {
  display: flex;
  gap: 20px;
  font-size: 13px;
  color: var(--brand-color-two-text);
  position: absolute;
  right: 10px;
  top: 50px;
}
.save-cancel-textarea-checklist {
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: var(--brand-color-two-text);
  justify-content: flex-end;
}

.check-list-title .save-cancel-textarea-checklist {
  position: absolute;
  top: 1px;
  z-index: 2;
  right: 0px;
}

.save-cancel-textarea-checklist-block textarea {
  border: solid 1px var(--brand-color-one-border);
  border-radius: 7px;
  padding: 5px 10px;
}
.icon-ticket-description svg {
  position: absolute;
  left: -20px;
  top: 1px;
  font-size: 14px;
}

.description_image_block_item.active_url {
  padding: 10px 5px;
}

.ticket_main_files-groups {
  display: flex;
  gap: 14px;
  padding-bottom: 20px;
  flex-wrap: wrap;
}
.ticket_main_files-group {
  position: relative;
  padding: 12px 12px;
  width: calc(100% / 2 - 7px);
  border-radius: 5px;
  background-color: #f7f8fb;
  box-sizing: border-box;
}
.ticket_main_files-group_name {
  color: rgb(10 137 211);
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.ticket_main_files-group_name_edit {
  position: relative;
  height: 16px;
}
.ticket_main_files-group_name_edit textarea {
  width: calc(100% - 60px);
  background: #fff0;
  border-bottom: solid #c4c4c4 1px;
  padding: 0px;
  margin-top: -1px;
}
.ticket_main_files-group_name_edit .save,
.ticket_main_files-group_name_edit .cansel {
  position: absolute;
  top: 0px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.ticket_main_files-group_name_edit .save {
  right: 30px;
  background: #9be954;
  color: #425b2a;
}
.ticket_main_files-group_name_edit .cansel {
  right: 0px;
  background: #d86e6e;
  color: #ffffff;
}
.ticket_main_files-group_edit_icon {
  display: none;
  position: absolute;
  top: 0px;
  right: -5px;
}
.ticket_main_files-group_name span {
  position: relative;
  display: block;
  width: 100%;
  word-wrap: break-word;
  line-height: 16px;
}
.ticket_main_files-group_name:hover .ticket_main_files-group_edit_icon {
  display: block;
}
.ticket_main_files-group_name_icon {
  position: absolute;
  left: 0px;
  top: -2px;
}
.ticket_main_files-group_del {
  color: #cb2f2f;
  position: absolute;
  padding-left: 22px;
  font-size: 12px;
  cursor: pointer;
  right: 10px;
  bottom: 12px;
  opacity: 0;
  transition: 0.2s;
}
.ticket_main_files-group:hover > .ticket_main_files-group_del {
  opacity: 1;
}
.ticket_main_files-image_items {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 10px 0px 30px 0px;
}
.ticket_main_files-image_item {
  width: 50px;
  height: 50px;
  position: relative;
  opacity: 1;
  transition: 0.2s;
  cursor: pointer;
}
.ticket_main_files-image_item:hover img {
  opacity: 0.6;
}
.ticket_main_files-image_item a {
  display: block;
  width: 100%;
  height: 100%;
}
.ticket_main_files-image_item img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: 1px 1px 7px #0000003b;
}
.ticket_main_files-image_del {
  position: absolute;
  top: -7px;
  height: 17px;
  overflow: hidden;
  background: #fff;
  border-radius: 20px;
  right: -7px;
  width: 17px;
  border: solid #c60000 1px;
  display: none;
  cursor: pointer;
}
.ticket_main_files-image_item:hover .ticket_main_files-image_del {
  display: block;
}
.ticket_main_files-image_del svg {
  font-size: 13px;
  color: #c60000;
  position: absolute;
  top: 1.4px;
  left: 1px;
}
.ticket_main_files-other_items {
  padding-top: 10px;
  max-width: 100%;
  font-size: 13px;
  line-height: 15px;
}
.ticket_main_files-other_item {
  padding-top: 11px;
  width: 100%;
  display: flex;
  gap: 5px;
  max-width: 100%;
}
.ticket_main_files-other_item a {
  max-width: 100%;
}
.ticket_main_files-other_icon svg {
  font-size: 14px;
  margin-top: -3px;
}
.ticket_main_files-other_del {
  display: inline-flex;
  color: #c10000;
  padding-left: 4px;
}
.ticket_main_files-other_del {
  font-size: 12px;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
}
.ticket_main_files-other_item:hover .ticket_main_files-other_del {
  opacity: 1;
}

.ticket_main_files-other_icon {
  padding: 0px 5px;
  color: #c10000;
  display: inline-flex;
}
.ticket_main_files-other_size {
  display: inline-flex;
}
.ticket_main_files-other_type {
  color: #cf3e3f;
}
.ticket_main_files-other_name {
  color: #000;
  width: 100%;
  word-wrap: break-word;
}
.ticket_main_files-add_file {
  padding-top: 10px;
}
.ticket_main_files-add_file label {
  color: #7e7e7e;
  position: relative;
  padding-left: 22px;
  font-size: 12px;
  cursor: pointer;
}
.ticket_main_files-add_file_icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 17px;
}
.ticket_main_files-group_small {
  width: max-content;
  height: max-content;
}
.ticket_main_files-add_group {
  color: #7e7e7e;
  position: relative;
  padding-left: 22px;
  font-size: 12px;
  cursor: pointer;
}
.ticket_main_files-add_group svg {
  font-size: 17px;
  position: absolute;
  left: 0px;
}

.arr_map_files_image {
  width: 50px;
  height: 50px;
  margin: 0px;
}
.arr_map_files_image:hover .description_image_block_icon_deletefile {
  height: 21px;
  opacity: 1;
  transition: 0.5s;
}

.extension_file {
  width: 100%;
  height: 100%;
  position: relative;
}
.extension_file svg {
  font-size: 50px;
  line-height: 50px;
}
.extension_file span {
  position: absolute;
  bottom: 8px;
  text-align: center;
  display: block;
  left: 0px;
  right: 0px;
  font-size: 11px;
  color: #000;
  text-transform: uppercase;
}
.description_image_block_icon_deletefile {
  cursor: pointer;
  height: 0px;
  overflow-y: hidden;
  opacity: 0;
}
.arr_map_files_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.button_cursor {
  cursor: pointer;
}
.button_cursor label {
  cursor: pointer;
}
.ticket_main_files-other_download {
  display: flex;
  gap: 10px;
}
.ticket_main_files-other_download .spinner-border {
  height: 15px;
  width: 15px;
  --bs-spinner-border-width: 0.15em;
}

/*------------Main------------------*/

/*Control Panel*/

.ticket_block_control_panel_all {
  width: calc(21% - 10px);
  height: min-content;
  position: sticky;
  top: 70px;
  padding: 10px;
}
.status_hot {
  border-radius: 5px 5px 0 0;
  margin: -10px -10px 0px -10px;
}
.status_online {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.status950,
.status501,
.status502,
.status503,
.status504,
.status505,
.status506,
.status507,
.status508,
.status509,
.status510,
.status511,
.status512,
.status513,
.status514,
.status515,
.status516,
.status517 {
  color: var(--brand-color-four-text);
  background-color: var(--brand-color-five-background);
  border: solid 1px var(--brand-color-one-border);
}
.status10 {
  color: var(--brand-color-three-text);
  background-color: var(--brand-color-four-background);
  border: solid 1px var(--brand-color-one-border);
}
.status500 {
  color: var(--brand-color-six-text);
  background-color: var(--brand-color-six-background);
  border: solid 1px var(--brand-color-one-border);
}
.status10 {
  color: var(--brand-color-three-text);
  background-color: var(--brand-color-four-background);
  border: solid 1px var(--brand-color-one-border);
}
.status20 {
  color: var(--brand-color-seven-text);
  background-color: var(--brand-color-seven-background);
  border: solid 1px var(--brand-color-one-border);
}
.status980,
.status990 {
  color: var(--brand-color-eight-text);
  background-color: var(--brand-color-eight-background);
  border: solid 1px var(--brand-color-one-border);
}
.status970 {
  color: var(--brand-color-eight-text);
  background-color: var(--brand-color-eleven-background);
  border: solid 1px var(--brand-color-one-border);
}
.status-next-one {
  width: calc(100% - 40px);
}
.even-block-area {
  width: 40px;
}
.status-next-one-button {
  color: var(--brand-color-one-background);
  background-color: var(--brand-color-one-button);
  opacity: 0.9;
}
.status-next-one-button:hover {
  opacity: 1;
}
.even-button-more .even-button-more-no-active {
  display: block;
  font-size: 12px;
}
.even-button-more .even-button-more-active {
  display: none;
}
.status-next-one-button,
.even-button {
  min-height: 33px;
  width: 100%;
  border: 1px solid var(--brand-color-one-border);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 14px;
  text-align: center;
}
.even-button-more {
  position: relative;
  z-index: 2;
  background: #ffffff;
  color: #8d8e8f;
  transition: 0.3s;
}

.even-button-more:hover {
  color: #545252;
  border: solid #979797 1px;
}
.even-button-more svg {
  font-size: 22px;
}
.even-button-other-statuses {
  display: none;
}
.even-button-more.active-even-button .even-button-more-active {
  min-height: 33px;
  width: 100%;
  border: 1px solid var(--brand-color-one-border);
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.even-button-other-statuses-active {
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  max-width: 100px;
  max-height: 250px;
  width: max-content;
  z-index: 25;
  position: absolute;
  top: 95px;
  padding: 5px;
  border-radius: 7px;
  right: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}
.control-panel-hot-button-more-value:hover {
  background-color: var(--brand-color-three-background);
  cursor: pointer;
  padding: 5px;
}
.control-panel-hot-button-more-value {
  padding: 5px;
}
.control-panel-title-value {
  position: relative;
  min-height: 37px;
}
.control_panel_items_block {
  background-color: var(--brand-color-eight-text);
  overflow-y: scroll;
  max-width: 200px;
  max-height: 300px;
  color: var(--brand-color-one-text);
  font-size: 14px;
  border: 1px solid var(--brand-color-one-border);
  border-radius: 7px;
  padding: 5px 0px 5px 5px;
  width: max-content;
  position: absolute;
  z-index: 10;
}
.control_panel_default_value {
  font-weight: 700;
  padding: 5px;
}
.control_panel_selector_value {
  padding: 5px;
  font-size: 12px;
  line-height: 14px;
}
.control_panel_default_value:hover {
  background-color: var(--brand-color-three-background);
  cursor: pointer;
  padding: 5px;
  border-radius: 7px;
}
.control_panel_selector_value:hover {
  background-color: var(--brand-color-three-background);
  cursor: pointer;
  padding: 5px;
  border-radius: 7px;
}
.selector-tittle-control-panel {
  font-weight: 600;
  padding: 2px 5px;
  cursor: pointer;
  width: max-content;
}
.selector-tittle-control-panel.cursor_no_drop {
  padding: 0px 5px !important;
}
.selector-value-block-control-panel.cursor_no_drop {
  border: none !important;
  padding: 0px 5px !important;
  color: var(--brand-color-eleven-text);
}

.filled_in {
  color: var(--brand-color-eleven-text) !important;
}

/*-------------Control Panel end----------------*/

/*Check List*/

.check_list {
  background: #f8f9fa;
  box-sizing: border-box;
  margin: 15px 0px;
  position: relative;
  transition: 170ms linear all;
  border-radius: 7px;
}
.check_list_title_checks_block {
  padding: 15px;
}
.check-list-title {
  position: relative;
  display: flex;
  gap: 20px;
  border-bottom: 2px solid var(--brand-color-one-border);
  padding-bottom: 10px;
}
.check_list_title_name_value {
  font-size: 13px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  gap: 5px;
  color: #595959;
}
.icon_cheklist_title {
  opacity: 0;
  transition: 0.3s;
}
.check_list_title_name_value:hover .icon_cheklist_title {
  opacity: 1;
}
.textarea-save-cancel-textarea-checklist-block {
  position: absolute;
  width: 100%;
  background: #f8f9fa;
  z-index: 2;
}
.textarea-save-cancel-textarea-checklist-block
  .save-cancel-textarea-checklist-block
  textarea {
  width: calc(100% - 200px);
  position: relative;
  z-index: 1;
}
.check-list-title-name {
  min-height: 20px;
}
.check_list_title_state {
  color: var(--brand-color-two-text);
  font-size: 12px;
}
.check_list_title_delete {
  position: absolute;
  right: 0px;
  cursor: pointer;
  color: var(--brand-color-two-text);
  font-size: 13px;
  transition: 0.3s;
}
.check_list_title_delete:hover {
  color: rgb(172 0 0);
}
.check-list-check {
  position: relative;
  display: flex;
  gap: 20px;
  padding: 15px 5px;
  border-bottom: 1px solid var(--brand-color-one-border);
  align-items: center;
}
.check_list_check_items_value {
  width: 90%;
  position: relative;
}
.check_list_check_items_value textarea {
  margin: -7px;
}
.check_box_punkt {
  cursor: pointer;
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.check_box_punkt svg {
  width: 18px;
  height: 18px;
}
.check_box_completed svg {
  width: 18px;
  height: 18px;
  color: var(--brand-color-nine-border);
}
.check_box_punkt {
  cursor: pointer;
}
.check_list_check_value {
  cursor: pointer;
  width: 100%;
  padding-right: 20px;
}
.check_list_check_value textarea {
  border: solid 1px var(--brand-color-one-border);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  padding: 7px;
  height: max-content;
}
.text-decoration {
  width: max-content;
  color: var(--brand-color-two-text);
  font-size: 13px;
  position: relative;
  text-decoration: line-through;
  max-width: 90%;
}
.check_list_punkt_delete {
  cursor: pointer;
  position: absolute;
  right: 10px;
  color: var(--brand-color-two-text);
  height: 0px;
  overflow-y: hidden;
  opacity: 0;
}
.check-list-check:hover .check_list_punkt_delete {
  height: 20px;
  opacity: 1;
  transition: 0.5s;
}
.check_list_punkt_delete svg {
  color: var(--brand-color-one-svg);
  width: 20px;
  height: 20px;
}
.textarea-checklist-check-button {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 5px;
}

.check_list_check_items_value .textarea-checklist-check-button {
  padding-top: 10px;
  padding-right: 8px;
}

.check-list-checks-block {
  padding: 20px 5px;
  padding-bottom: 0px;
}
.add-new-item {
  color: rgb(64 64 64);
  font-size: 13px;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 45px;
}
.add-check-list {
  cursor: pointer;
  width: max-content;
  border-bottom: dashed 1px var(--brand-color-two-line);
  font-weight: 600;
}

/*-------------Check List end----------------*/

/*Tabs*/
.ticket_block_tab_button_content {
  margin-bottom: 40px;
  padding: 15px;
}
.tab-title-button.active {
  background: var(--brand-color-ten-background);
  border-radius: 7px;
  height: 35px;
}
.comment-text-all-block {
  padding-bottom: 20px;
}
.input-ticket-box-text {
  position: relative;
  padding: 12px 12px;
  max-width: 820px;
  border-radius: 5px;
  background-color: #f7f8fb;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}
.comment-text-user-date-message .input-ticket-box-text {
  padding: 0px;
}
.input-ticket-box-text textarea {
  width: 100%;
  max-width: 100%;
  border: 1px solid rgb(239 239 239);
  border-radius: 5px;
  padding: 10px;
}

.add_comment_button__addimage {
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: var(--brand-color-two-text);
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.comment-text-user-date-message {
  position: relative;
  padding: 8px 12px;
  max-width: 820px;
  border-radius: 5px;
  background-color: #f7f8fb;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}
.mark_result .comment-text-user-date-message {
  background: #ebffdb;
}
.comment-user-date {
  display: flex;
  gap: 10px;
  font-size: 13px;
  width: max-content;
}
.comment-user {
  color: rgb(10 137 211);
}
.quote_tabs_comment {
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  background-color: var(--brand-color-ten-background);
  border-left: solid var(--brand-color-three-border) 3px;
}
.quote_comment_user {
  font-size: 11px;
  font-weight: 600;
}
.comment-date {
  color: rgb(178 178 178);
  font-size: 12px;
  line-height: 20px;
}

.logs-text-all-block {
  display: grid;
  grid-template-columns: 200px 200px auto;
  padding: 20px 5px;
  border-bottom: solid 1px var(--brand-color-three-line);
}
.comment-block-ticket {
  position: relative;
}

.result_text {
  display: none;
}
.mark_result .result_text {
  display: block;
  position: absolute;
  right: 12px;
  top: 7px;
  color: rgb(105 159 63);
  padding: 0px;
}

/*-------------Tabs----------------*/

.control-panel-ticket-body {
  display: flex;
  gap: 10px;
}
.ticket-block-body {
  width: 79%;
  min-height: 350px;
  height: 100%;
}
.ticket-block-control-panel {
  position: sticky;
  top: 90px;
  width: 20%;
  min-height: 700px;
  height: 100%;
}

.icon-ticket-title svg {
  width: 15px;
  height: 15px;
}

.state-ticket-block {
  margin: 0px 0px 25px 0px;
  text-align: center;
}
.switches-state-ticket-block {
  margin: 10px 0px 30px 0px;
  text-align: center;
}
.switches-state-ticket-button {
  margin: 10px;
}
.value-state-ticket-block > div {
  margin: 0px 15px 25px;
}

.button-style {
  border-bottom: solid 1px var(--brand-color-one-button);
  cursor: pointer;
  width: max-content;
  color: var(--brand-color-eleven-text);
}
.save-textarea,
.cancel-textarea {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  background: #424242;
  padding: 0px 10px;
  border: 0px;
  color: #424242;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 3px;
  transition: 0.3s;
}
.save-textarea {
  background: #9be954;
  color: #425b2a;
}
.save-textarea:hover {
  background: #83d638;
  color: #161f0e;
}
.cancel-textarea {
  background: #d86e6e;
  color: #ffffff;
}
.cancel-textarea:hover {
  background: #bf4848;
}
.ticket-block-tab-button {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}
.tab-content-info {
  display: none;
  padding: 15px;
}
.tab-content-info.active {
  display: block;
}
.tab-title-button {
  cursor: pointer;
  height: 40px;
  box-shadow: 5px 4px 2px var(--brand-color-one-background);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.tab-title-button > div {
  padding: 15px;
}

.comment-message {
  padding: 5px 0px;
  font-size: 13px;
}
.comment-message pre {
  font-family: monospace;
  font-size: 13px;
  margin-bottom: 0px;
}
.comment-buttons-block {
  padding: 5px 8px 10px;
  display: flex;
  gap: 12px;
  font-size: 13px;
}
.comment-button {
  cursor: pointer;
  width: max-content;
  color: rgb(132 132 132);
  font-size: 12px;
  border-bottom: 0px;
  transition: 0.3s;
}
.comment-button:hover {
  color: #258ad4;
}

.control-panel-block {
  padding-bottom: 7px;
  min-height: 21px;
  position: relative;
  font-size: 11px;
}
.control_panel_date_control .selector-date-save-cancel {
  top: 14px;
  z-index: 99;
  background: #ffffff;
  padding: 2px;
  border-radius: 0px 5px 5px 0px;
  left: 110px;
  height: 30px;
}
.control_panel_date_control .control_panel_items_block {
  background: #ffffff;
  border-radius: 0px;
  height: 20px;
  padding: 0px 0px 0px 4px;
  width: auto;
  min-width: unset;
  border: 0px;
  overflow-y: hidden;
  box-shadow: none !important;
  position: unset;
  font-size: 12px;
  margin-top: -1px;
}
.selector-value-block-control-panel {
  color: var(--brand-color-two-text);
  background: #fff;
  padding: 0px 5px;
  cursor: pointer;
}

.date-input-ticket-control-panel input {
  border: none;
  outline: none;
  font-size: 11px;
  width: 105px;
  background: #ffffff;
  height: 20px;
}
.selector-date-save-cancel {
  display: flex;
  gap: 2px;
  position: absolute;
  left: 100px;
  top: 25px;
}
.selector-date-save,
.selector-date-cancel {
  cursor: pointer;
}
.selector-date-save svg {
  width: 24px;
  height: 24px;
  fill: #6fc620;
}
.selector-date-cancel svg {
  width: 24px;
  height: 24px;
  fill: #cf0808;
}

.form-add-file {
  padding: 10px 0px;
}

.textarea-checklist-list {
  border: solid 1px var(--brand-color-one-border);
  border-radius: 5px;
}

.hot-button-control-panel {
  display: flex;
  gap: 10px;
}
.hot-button-control-panel.nogap {
  gap: 0px;
}

.hot-button-control-panel.nogap .status-next-one {
  width: 100%;
}

.even-button-other-statuses.even-button-other-statuses-active {
  display: block;
}

.even-button-more.active-even-button .even-button-more-no-active {
  display: none;
}

.even-button.even-button-more.active-even-button {
  border: none;
}
.arr-map-block-img {
  display: flex;
  flex-wrap: wrap;
}

.arr-map-item-img {
  display: flex;
  padding: 5px 5px;
  gap: 5px;
}
.arr-map-item-img svg {
  cursor: pointer;
}
.arr-map-item-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: solid #e3e3e3 1px;
  box-shadow: 0px 0px 10px #00000024;
}
.task-notes-items {
  max-width: 850px;
}

.even-button-other-statuses-active::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
}
.even-button-other-statuses-active::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.even-button-other-statuses-active:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

.control_panel_items_block::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
}
.control_panel_items_block::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.control_panel_items_block:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

@media (max-width: 576px) {
  .control-panel-ticket-body {
    display: block;
  }
  .ticket-block-body {
    width: 100%;
    position: absolute;
    top: 50px;
  }
  .btn-block-mobile {
    color: var(--c-one);
    background-color: var(--c-three);
    padding: 5px 15px;
    border-radius: 7px;
    cursor: pointer;
    text-align: center;
    margin: 5px 15px;
  }
  .btn-block-mobile.active {
    position: absolute;
    top: 600px;
    z-index: 500;
    right: 5px;
  }

  .ticket_block_control_panel_all {
    display: none;
  }
  .ticket_block_control_panel_all.active {
    display: block;
    height: max-content;
    width: 100%;
    background-color: #282a36;
    box-shadow: 0px 10px 100px;
    padding: 20px 20px 70px 20px;
    margin-top: 20px;
  }
  .selector-value-block-control-panel {
    background-color: #282a36;
  }
  .filled_in {
    color: var(--c-one) !important;
  }
  .selector-tittle-control-panel {
    color: var(--c-one);
  }
  .selector-value-block-control-panel.cursor_no_drop {
    color: var(--c-one) !important;
  }
  .ticket_main_files-groups {
    display: block;
  }
  .ticket_main_files-group {
    padding: 25px 12px;
    width: 100%;
    margin: 15px 0px;
  }
  .check-list-title {
    display: block;
  }
  .check_list_title_delete {
    top: 0px;
  }
  .template_name_block {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 15px;
    padding: 5px;
  }
  .mobile_ticket_name_value {
    padding-top: 5px;
  }
  .ticket-block-body-tabs-content {
    padding-top: 5px;
  }
  .add-comment-mobile {
    padding-bottom: 55px;
  }
}
