:root {
  /*text*/
  --brand-color-one-text: rgb(38, 38, 38);
  --brand-color-two-text: rgb(140, 140, 140);
  --brand-color-three-text: rgb(255, 77, 79);
  --brand-color-four-text: rgb(82, 196, 26);
  --brand-color-six-text: rgb(19, 194, 194);
  --brand-color-seven-text: rgb(202, 152, 0);
  --brand-color-eight-text: rgb(255, 255, 255);
  --brand-color-nine-text: rgb(97, 97, 97);
  --brand-color-ten-text: rgb(22, 119, 255);
  --brand-color-eleven-text: rgb(0 0 0);

  /*background*/
  --brand-color-one-background: rgb(255, 255, 255);
  --brand-color-two-background: rgb(230 244 255);
  --brand-color-three-background: rgb(245 245 245);
  --brand-color-four-background: rgb(255, 241, 240);
  --brand-color-five-background: rgb(246, 255, 237);
  --brand-color-six-background: rgb(230, 255, 251);
  --brand-color-seven-background: rgb(255, 247, 221);
  --brand-color-eight-background: rgb(195, 195, 195);
  --brand-color-nine-background: rgb(66, 74, 84);
  --brand-color-ten-background: rgb(239 247 255);
  --brand-color-eleven-background: rgb(219 127 60);
  --brand-color-twelve-background: rgb(250, 250, 251);
  --bran-color-thirteen-background: rgb(156 229 158);
  --brand-color-14-background: rgb(0 128 0);
  --brand-color-15-background: rgb(66, 74, 84, 0.3);

  /*border*/
  --brand-color-one-border: rgb(240, 240, 240);
  --brand-color-select-border: rgb(219, 219, 219);
  --brand-color-hover-border: rgb(54, 159, 244);
  --brand-color-two-border: rgb(140, 140, 140);
  --brand-color-nine-border: rgb(54, 159, 244);
  --brand-color-three-border: rgb(22, 119, 255, 0.5);
  --brand-color-four-border: rgb(182, 182, 182);

  /*line*/
  --brand-color-one-line: rgb(0 0 0);
  --brand-color-two-line: rgb(140, 140, 140);
  --brand-color-three-line: rgb(231, 231, 231);
  --brand-color-four-line: rgb(22, 119, 255, 0.5);

  /*svg*/
  --brand-color-three-svg: rgb(255, 77, 79);

  /*scrollbar*/
  --brand-color-one-scrollbar: rgb(95, 195, 239);

  /*button*/
  --brand-color-one-button: rgb(22, 119, 255);
  --brand-color-two-button: rgb(167, 192, 229);
}

/*NEW*/

:root {
  --c-one: rgb(255, 255, 255);
  --c-two: rgb(245, 245, 245);
  --c-three: rgb(22, 119, 255);
  --c-three-hover: rgb(2, 91, 216);
  --c-four: rgb(35, 121, 115);
  --c-fife: rgb(255, 17, 0);
  --c-six: rgb(109, 109, 109);
  --c-seven: rgb(0, 128, 0);
  --c-eight: rgb(236, 244, 255);
  --c-nine: rgb(204, 204, 204);
  --c-nine-light: rgb(242, 242, 242);
  --c-ten: rgb(132, 183, 255);
  --c-eleven: rgb(70, 70, 70);
  --c-twelve: rgb(240, 240, 240);
  --c-thirteen: rgb(70, 216, 161);
}
