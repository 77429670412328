
.chat_content_items_height{
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 10px;}



  .chat_content_items_height::-webkit-scrollbar {
    width: 3px; /* Ширина полосы прокрутки */
    background-color: #f3f6fb; /* Цвет фона */
}

.chat_content_items_height::-webkit-scrollbar-thumb {
    background-color: #3287fd79; /* Цвет бегунка */
    border-radius: 10px; /* Радиус скругления */
}



.chat {
  width: 70px;
  position: fixed;
  top: 60px;
  right: 0px;
  overflow-y: auto;
  transition: 0.3s;
  z-index: 20;
  bottom: 0px;
  background: var(--brand-color-15-background);
}
.chat_active {
  width: 100%;
}

.chat_center {
  background: #fff;
  border-left: solid #f6f6f6 1px;
}

.chat_right {
  background: #f6f7f8;
  position: relative;
}
.chat_left {
  font-size: 10px;
  box-shadow: 10px 0px 15px #00000033;
  z-index: 9;
  width: 70px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
}
.chat_left_btn {
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;
}
.chat_left_btn:hover {
  opacity: 1;
}
.chat_right_area {
  position: relative;
  height: 100%;
  overflow: hidden;
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
}
.chat_right_top {
  position: absolute;
  top: 0px;
  min-height: 50px;
  background: #f7f7f7;
  z-index: 2;
  left: 0px;
  right: 0px;
}
.chat_right_top_name {
  line-height: 24px;
  font-size: 18px;
  padding-left: 20px;
  padding-top: 12px;
}
.participants_area {
  position: relative;
}
.participants {
  padding-left: 20px;
  display: flex;
  gap: 1px 15px;
  padding-bottom: 6px;
  width: 100%;
  flex-wrap: wrap;
  height: 20px;
  overflow: hidden;
  padding-right: 20px;
}
.participants_area.active .participants {
  height: auto;
  overflow: initial;
}
.participant_item {
  font-size: 10px;
  line-height: 18px;
  height: 18px;
  color: #7d7d7d;
  position: relative;
  padding-left: 9px;
}
.participant_item:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0px;
  background: #ff6565;
  border-radius: 15px;
  top: 6px;
}
.participant_item.online:before {
  background: #28c316;
}
.button-participants-style {
  position: absolute;
  right: 0px;
  top: -3px;
  height: 18px;
  padding: 0px 5px;
  cursor: pointer;
}
.button-participants-no-active {
  display: block;
}
.button-participants-active {
  display: none;
}
.participants_area.active .button-participants-no-active {
  display: none;
}
.participants_area.active .button-participants-active {
  display: block;
}

.chat_right_top_setup {
  cursor: pointer;
  display: flex;    
  justify-content: center;
  align-items: center;
}

.chat_right_top_setup .chat_right_top_setup_open {
  display: block;
}
.chat_right_top_setup .chat_right_top_setup_close {
  display: none;
}
.chat_right_top_setup.active .chat_right_top_setup_open {
  display: none;
}
.chat_right_top_setup.active .chat_right_top_setup_close {
  display: block;
}

.chat_right_top_setup svg {
  font-size: 28px;
  color: #aaaaaa;
}
.chat_right_middle {
  position: absolute;
  top: 57px;
  bottom: 50px;
  width: 100%;
}
.chat_right_middle.full_height{
  margin-bottom:-50px;
}
.chat_right_bottom {
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 15px #0000000a;
}

.chat_download_past_message{
  background: linear-gradient(to top, #d3e9ff91, transparent);
  padding: 10px;
  margin: 0px 10px 10px 10px;
  text-align: center;
  color: #6688a5;
  border-radius: 0px 0px 10px 10px;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.chat_download_past_message:hover{
  opacity: 1;
}

.lower-chat-bar {
  display: grid;
  grid-template-columns: 40px 40px auto 50px;
  gap: 5px;
}

.file_paste_block {
  position: absolute;
  height: 0px;
  overflow: hidden;
  top: 0px;
  background-color: white;
  border-bottom: solid #ebebeb 1px;
  transition: 0.5s;
}
.file_paste_block.active {
  height: 60px;
  top: -60px;
}
.file_paste_block.active.active_top {
  height: 60px;
  top: -110px;
}

.file_paste_items {
  display: flex;
  gap: 15px;
  height: 60px;
  padding: 10px 0px;
}
.file_paste_item {
  height: 40px;
  position: relative;
  border-radius: 7px;
}
.file_paste_item_other{
  height: 40px;
  line-height: 40px;
  background: #ececec;
  padding: 0px 10px;
  border-radius: 10px;
}
.file_paste_item img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: 0px 0px 11px #00000029;
}
.file_paste_del {
  position: absolute;
  top: -3px;
  right: 1px;
  opacity: 0;
  transition: 0.3s;
}
.file_paste_del svg {
  color: var(--brand-color-three-svg);
  cursor: pointer;
}
.file_paste_item:hover .file_paste_del {
  opacity: 1;
}
.file_paste_item:hover img {
  opacity: 0.5;
}

.add_file_chat,
.add_file_label {
  height: 100%;
}
.add_file_label label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_file_label svg {
  font-size: 36px;
  background-color: #eaeaea;
  padding: 5px;
  border-radius: 3px;
}
.chat_textarea_rows {
  height: 50px;
  padding: 14px 0px;
}
.chat_textarea_rows textarea {
  padding: 0px;
  width: 100%;
  height: 22px;
  min-height: 22px;
  font-size: 12px;
}
.chat_textarea_rows textarea,
.chat_textarea_rows textarea:focus,
.chat_textarea_rows textarea:hover {
  border: 0px;
  outline: none;
}
.chat_textarea_message_button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chat_textarea_message_button svg {
  color: #3286fd;
  font-size: 36px;
  background-color: #eaeaea;
  padding: 5px;
  border-radius: 3px;
}
.container_chat {
  margin-right: 0px;
  box-shadow: 0px -20px 50px var(--brand-color-one-line);
  height: 100%;
}
.container_chat > div {
  height: 100%;
}

.chat-item_list_chatname_noactive {
  width: 45px;
  height: 45px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-item_list_chatname_noactive svg {
  max-width: 100%;
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.chat-item_list_chatname_noactive img {
  border-radius: 45px;
  box-shadow: 0 0 11px #00000029;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.message_new {
  animation: change_message_new 0.8s ease infinite;
}

@keyframes change_message_new {
  0% {
    border: solid #3186fe 0px;
  }
  50% {
    border: solid #3186fe 7px;
  }
  100% {
    border: solid #3186fe 0px;
  }
}
/*chat_active*/

.chat_active .chat_center {
  border-left: none;
  position: relative;
}
.chat_active .chat_center_area {
  overflow-y: scroll;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 70px;
  right: 0px;
  box-shadow: 5px 0px 20px #0000000d;
  z-index: 2;
}
.chat_center_scroll {
  width: 100%;
}

.chat_active .chat_p_2_svg_card_text {
  text-align: center;
}

.chat_active .chat_p_2_svg svg {
  width: 25px;
  height: 25px;
}

.chat_active .chat_list_item_image img {
  width: 45px;
  height: 45px;
}

.chat_active .chat_list_item_image_chatname {
  display: flex;
  gap: 20px;
}
.chat_active .chat-item_list_chatname_main {
  cursor: pointer;
  padding: 18px 5px 18px 28px;
}
.chat-item_chatname_active {
  position: relative;
}
.chat-item_chatname_active_logo {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.chat-item_chatname_active img {
  max-width: 100%;
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 45px;
  box-shadow: 0px 0px 11px #00000029;
}
.chat-item_chatname_active_info {
  height: 35px;
  padding-left: 48px;
}
.chat-item_chatname_active_name {
  font-size: 14px;
  line-height: 20px;
}
.chat-item_chatname_active_text {
  font-size: 10px;
  color: #9c9c9c;
  line-height: 16px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-item_list_chatname_main.active {
  background-color: var(--brand-color-ten-background);
}

.chat_message_item {
  padding: 7px 30px;
  position: relative;
}
.chat_message_item_avatar {
  position: absolute;
}
.chat_message_item_avatar img {
  max-width: 100%;
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 45px;
  box-shadow: 0px 0px 11px #00000029;
}

.my_comment .chat_message_item_block {
  margin-left: auto;
  margin-right: 0px;
  border-radius: 10px 0px 10px 10px;
  background: #d3e9ff;
}
.chat_message_item_block.delete_comment{
  opacity: 0.6;
}
                        


                    
                    
.chat_message_item_block {
  margin-left: 50px;
  border-radius: 0px 10px 10px 10px;
  background: #fff;
  padding: 10px 20px;
  width: max-content;
  max-width: 80%;
  box-shadow: 0px 0px 17px #0000000f;
  position: relative;
}
.chat_message_item_block.viewed_new_comment{
  box-shadow: 0px 0px 8px 0px #4185ff42;
  background: #f5faff;
}
.chat_message_item_user {
  font-size: 11px;
  color: #7d7d7d;
  padding-bottom: 5px;
  padding-right: 90px;
  position: relative;
}
.chat_message_item_files {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.chat_message_item_file {
  padding-bottom: 10px;
}
.chat_message_item_file img {
  max-width: 100%;
  border: solid #dcdcdc 1px;
  max-height: 200px;
  cursor: pointer;
}



.chat_message_item_files_other{
  padding-bottom: 10px;
}
.chat_message_item_files_other_item{
  display: flex;
  gap: 6px;
}
.chat_message_item_files_other_name{

}
.chat_message_item_files_other_icon{
  color: #c10000;
  margin-top: -1px;
}
.chat_message_item_files_other_size{

}



.chat_message_item_text {
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  word-break: break-all;
}
.chat_message_item_text pre{
  margin-bottom:0px;
}
.chat_message_item.my_comment .chat_message_item_text pre{
  text-align: left;
}
                    
                  
.chat_indicate_new_message{
  background: #e6ecf591;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 11px;
  margin-top: 5px;
  color: #7e879c;
}                        
                    

.chat_message_item_text svg {
  font-size: 18px;
}

.my_comment .chat_message_item_date {
  text-align: right;
  padding-left: initial;
  padding-right: 22px;
}
.chat_message_item_date {
  color: #a8a8a8;
  font-size: 9px;
  padding-top: 6px;
  padding-left: 70px;
  position: relative;
}
.chat_message_item_date.lite_info{    padding-left: 55px;}
.chat_message_item_date.lite_info .iocheckmarkdoneoutline{display:none;}
 
.iocheckmarkdoneoutline {
  position: absolute;
  top: 3px;
  left: 50px;
}

.my_comment .iocheckmarkdoneoutline {
  position: absolute;
  right: 0px;
  top: 3px;
}

.chat_message_item .ioalertcircleoutline_svg,
.chat_message_item .iocheckmarkdoneoutline_svg {
  position: absolute;
  transition: 0.3s;
  cursor: pointer;
}
.chat_message_item.my_comment .ioalertcircleoutline_svg,
.chat_message_item.my_comment .iocheckmarkdoneoutline_svg {
  position: absolute;
  right: 0px;
}

.chat_message_item .ioalertcircleoutline_svg {
  opacity: 0;
}
.chat_message_item:hover .ioalertcircleoutline_svg {
  opacity: 1;
}
.chat_message_item:hover .iocheckmarkdoneoutline_svg {
  opacity: 0;
}

.iocheckmarkdoneoutline svg {
  font-size: 17px;
}
.iocheckmarkdoneoutline.active svg {
  font-size: 17px;
  color: #28c316;
}

.chat_message_item_answer_btn {
  opacity: 0;
  left: auto;
  right: -10px;
  text-align: right;
  font-size: 12px;
  
  width: 90px;
  padding-top: 2px;
  cursor: pointer;
  position: absolute;
  top: -3px;
  color: #1b63ca;
}

.my_comment .chat_message_item_answer_btn {
  top: -8px;
  right: -18px;
  font-size: 16px;
}

.chat_message_item_answer_area {
  border-left: solid #4a85bf 3px;
  background: #c6e1fc;
  padding: 5px 8px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.chat_message_item_answer_user {
  color: #11477b;
}
.chat_message_item_answer_message {
  color: #3e566d;
}

.chat_message_item_block:hover .chat_message_item_answer_btn {
  opacity: 1;
}
.chat_answer_area {
  position: absolute;
  height: 0px;
  top: 0px;
  background-color: white;
  border-bottom: solid #ebebeb 1px;
  overflow: hidden;
  transition: 0.5s;
}
.chat_answer_area.active {
  height: 50px;
  top: -50px;
  transition: 0.5s;
}
.chat_answer_area_icon svg {
  position: absolute;
  top: 11px;
  left: 16px;
  bottom: 0px;
  font-size: 28px;
  height: 28px;
  fill: #3286fd;
  color: #3286fd;
}
.chat_answer_area_user {
  position: absolute;
  left: 64px;
  top: 6px;
  color: #3286fd;
}
.chat_answer_area_message {
  position: absolute;
  left: 64px;
  top: 23px;
  right: 64px;
  overflow: hidden;
  color: #000;
}

.chat_answer_area_close svg {
  position: absolute;
  top: 11px;
  right: 16px;
  bottom: 0px;
  font-size: 28px;
  height: 28px;
  fill: #a81616;
  color: #a81616;
  cursor: pointer;
}

.emoji-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  overflow-y: hidden;
  z-index: 25;
  left: 15px;
  transition: 0.5s;
  width: 0px;
  height: 0px;
  bottom: 50px;
  border-radius: 7px;
}
.emoji-container.active {
  width: 230px;
  height: 165px;
}
.emoji-container.active > div {
  font-size: 18px;
}

.emoji-button-open {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji-button-open svg {
  font-size: 36px;
  background-color: #eaeaea;
  padding: 5px;
  border-radius: 3px;
}

.chat_message_item .big_emoji{
  font-size: 50px;
  line-height: 50px;
}
.chat_message_item .small_emoji{
  font-size: 18px;
}

.chat_right_setup {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 250px;
  right: -260px;
  bottom: 50px;
  transition: 0.3s;
  border: solid #dedede 1px;
  border-right: 0px;
  border-top: 0px;
  padding: 25px;
  overflow-y: scroll;
  
}
.chat_right_setup.active {
  right: 0px;
}
.chat_right_setup_logo {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}
.chat_right_setup_logo_img {
  width: 200px;
  height: 200px;
}
.chat_right_setup_logo_img img {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  object-fit: contain;
  box-shadow: 0px 0px 11px #00000029;
}
.chat_right_setup_logo_btn label {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 200px;
  width: 200px;
  opacity: 0;
  background: #0000008f;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.chat_right_setup_logo_btn svg {
  color: #fff;
  font-size: 80px;
}
.chat_right_setup_logo:hover label {
  opacity: 1;
}
.chat_right_setup_name {
  text-align: center;
  padding-bottom: 15px;
  border-bottom: solid #dde2e7 1px;
  padding-top: 15px;
}
.chat_right_setup_name .change-items-active {
  max-width: 100%;
  display: flex;
  position: relative;
}

.chat_right_setup_name .change-items-btn-save {
  position: relative;
}
.chat_right_setup_name .change-items-btn-save svg {
  right: -1px;
}

.chat_right_setup_name .change-items-icon {
  margin: 0 auto;
}
.chat_right_setup_name .change-items-value {
  font-size: 24px;
  color: #000000;
  line-height: 21px;
}
.chat_right_setup_name .change-items {
  gap: 10px;
}
.chat_right_setup_name svg {
  font-size: 20px;
  position: absolute;
  opacity: 1;
  top: 4px;
}
.chat_right_setup_name:hover svg {
  opacity: 1;
}

.chat_right_setup_users {
  padding-top: 15px;
}
.chat_right_setup_users_title {
  font-size: 12px;
  color: #b0b0b0;
}
.chat_right_setup_users_items {
  padding-top: 7px;
  padding-bottom: 10px;
}
.chat_right_setup_users_item {
  display: flex;
  padding-bottom: 4px;
}
.chat_right_setup_users_name {
  font-size: 12px;
  color: #000;
  line-height: 18px;
}
.chat_right_setup_users_del {
  padding-left: 5px;
  line-height: 16px;
}
.chat_right_setup_users_del svg {
  font-size: 12px;
  line-height: 12px;
  color: #0000003b;
  cursor: pointer;
}
.chat_right_setup_users_item:hover svg {
  color: #b00000;
}
.chat_right_setup_users .chat_right_setup_users_add {
  font-size: 12px;
}

.chat .edit-message-block {
  position: relative;
}
.chat .edit-message, .chat .delete-message{
  color: #464646;
  cursor: pointer;
  font-size: 10px;
}
.edit-message-text {
  color: #a8a8a8;
  font-size: 10px;
}
.chat_message_item.my_comment .edit-message-text{
text-align:right;
}
.edit-delete-message-block{
  display: flex;
  gap: 10px;
  padding-top: 8px;
  opacity: 0.5;
  justify-content: end;
}
.chat_message_item_block:hover .edit-delete-message-block{
  opacity: 1;
  transition:0.3s;
}
.chat-change-message-textareaautosize-block .edit-delete-message-block{opacity: 1;}
.chat-change-message-textareaautosize-block {
  border: 0px;
  outline: none;
}
.chat-change-message-textareaautosize-block textarea{

}

.chat-change-message-textareaautosize-block textarea {
  min-width: 400px;
  padding: 3px;
  border-radius: 3px;
  margin: -3px 0px -7px 0px;
}

.chat-change-message {
  display: flex;
  gap: 15px;
  padding: 5px 0px;
}

.message-viewed-block {
  position: relative;
}
.message-viewed {
  border-radius: 10px 0px 10px 10px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  left: -25px;
  padding: 3px 7px;
  position: absolute;
  top: -7px;
  width: max-content;
  z-index: 1;
  font-size: 10px;
}
.message-viewed-user {
  display: flex;
  gap: 5px;
}
.message-notviewed-user {
  display: flex;
  gap: 5px;
}
.message-viewed-user svg {
  color: #28c316;
  width: 16px;
  height: 16px;
  display: flex;
}
.chat_message_item.my_comment .message-viewed {
  right: 15px;
  left: auto;
}

/*
-
-
-
-
-
-
-
-
-
-
-
-
-
-
*/

.chat_active .chat_content_items::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
}
.chat_active .chat_content_items::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat_active .chat_content_items:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

.chat_active .list_item_user_chat::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
}
.chat_active .list_item_user_chat::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat_active .list_item_user_chat:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

.chat::-webkit-scrollbar {
  width: 0px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
}
.chat::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

.chat_active .chat_items::-webkit-scrollbar {
  width: 5px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
}
.chat_active .chat_items::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat_active .chat_items:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

.chat_active .chat_setup::-webkit-scrollbar {
  width: 5px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
}
.chat_active .chat_setup::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat_active .chat_setup:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
.file_paste_block::-webkit-scrollbar {
  width: 0px;
}
.chat_right_middle::-webkit-scrollbar {
  width: 0px;
}
.chat_right_setup::-webkit-scrollbar {
  width: 0px;
}
.chat_active .chat_center_area::-webkit-scrollbar {
  width: 0px; /* Ширина полосы прокрутки */
  height: 0px; /* Высота полосы прокрутки */
  position: absolute;
}
.chat_active .chat_center_area::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}
.chat_active .chat_center_area:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
