@import "color.css";
@import "font.css";
@import "menu.css";
@import "filterpanel.css";
@import "auth.css";
@import "appheader.css";
@import "main.css";
@import "chat.css";
@import "tableticket.css";
@import "ticket.css";
@import "modal.css";
@import "counterparties.css";
@import "roles.css";
@import "staff.css";
@import "general.css";
@import "user.css";
@import "registration.css";
@import "functionchange.css";
@import "counterpartie.css";
@import "structure.css";
@import "branch.css";
@import "counterpartiecontacts.css";
@import "password.css";
@import "payment.css";
@import "galleryslider.css";
@import "mobilestyle.css";
@import "captcha.css";

body {
  width: 100%;
  margin: 0;
  min-width: 1200px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--brand-color-twelve-background);
}
.App {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
.textarea {
  width: 100%;
}

@media (max-width: 576px) {
  body {
    min-width: 250px;
  }
}
