.listroles_one_two {
  display: flex;
  gap: 1.5rem;
}
.listroles_one {
  width: 50%;
}
.listroles_two {
  width: 50%;
}
.listroles_one {
  padding: 15px 0px;
}
.roles_right_heading {
  position: relative;
}

.roles_block {
  display: grid;
  grid-template-columns: 350px 50px;
  padding: 10px 15px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.roles_block:hover {
  background: #f8f8f8;
}

.roles_block.active {
  background-color: var(--brand-color-ten-background);
  border-right: solid var(--brand-color-three-border) 3px;
}
.roles_title {
  padding: 15px;
}
.roles_name {
  display: flex;
  align-items: center;
}
.right_title .change-items-active {
  width: 100%;
}
.right_title .change-items-active textarea {
  width: 300px;
}

.roles_block .roles_name .change-items {
  display: grid;
  grid-template-columns: 190px 30px;
}
.roles_block .roles_name .change-items-value {
  width: max-content;
}
.listroles_two {
  padding: 15px;
}
.right_title {
  padding: 15px;
  min-height: 55px;
}
.right_title .functionChange article{
  width:max-content;
  position:relative;
}
.right_title .functionChange article:after{
  content: "🖍";
  position: absolute;
  color: #a8a8a8;
  right: -20px;
  font-size: 14px;
  line-height: 25px;
  cursor:pointer;
}
.right_title .functionChange.active article:after{
  display:none;
}
.roles-block .right_title .functionChange-article {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--c-eleven);
}
.listroles_rights_block {
  padding: 15px;
}
.listroles_rights_area {
  padding: 10px 0px;
  width: max-content;
}
.listroles_rights_items {
  display: grid;
  grid-template-columns: 320px 30px;
  padding: 3px 0px;
}
.listroles_rights_icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 25px;
  height: 25px;
}

.listroles_rights_icon svg {
  width: 20px;
  height: 20px;
  color: var(--brand-color-eight-background);
}
.listroles_rights_icon.active svg {
  color: var(--c-three);
  width: 20px;
  height: 20px;
}
.active_not_edited {
  cursor: inherit;
}
.title_roles {
  padding: 10px 0px;
}
