/* Main */

.ticketfastmain-description {
  width: 100%;
  min-height: 120px;
  margin: 20px 0px 20px 0px;
  position: relative;
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 5px;
  word-break: break-all;
}
.ticketfastmain .ticket_main_files-image_items {
  padding-bottom: 20px;
}

.ticketfastmain-number-title {
  color: var(--c-six);
}
/* Main */

/* ticketfastinfo */

.ticketfastinfo .even-button-other-statuses-active {
  right: 0px;
}

.nogap .status-next-one {
  width: 100%;
}
/* ticketfastinfo */

/* ticketfastcomment  */
.ticketfascomment-items-comment {
  position: relative;
}

.ticketfastcomment .a-btn-block-save {
  width: max-content;
}

.ticketfast-items-comment {
  position: relative;
}

.ticketfastcomment .comment-buttons-block {
  position: absolute;
  bottom: 0px;
  right: 120px;
}
.ticketfastcomment .comment-text-user-date-message {
  max-width: 100%;
}

.ticketfastcomment .aritineditor-value {
  min-height: 70px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}
.comment-message-false.comment-files-true .aritineditor-value {
  min-height: 5px;
}

.comment-buttons-block.comment-button-read {
  right: 10px;
}
/* ticketfastcomment  */
