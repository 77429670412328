.auth {
  background: url("/src/image/img/background_black.jpg") !important;
  justify-items: center;
  text-align: center;
  background-size: cover !important;
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-authblock {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.logowhite {
  border-right: solid 1px var(--brand-color-one-border);
  padding: 15px;
}
.logowhite img {
  width: 215px;
  padding: 15px;
}
.authblock {
  padding: 15px;
  width: 220px;
}
.authblock-tittle {
  color: var(--brand-color-eight-text);
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 10px;
}
.textarea-login {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
}
.authblock-pass-view {
  color: var(--brand-color-eight-text);
  padding: 10px 5px 5px 5px;
  display: flex;
  gap: 10px;
}

.authblock-pass-view label {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.authblock-fail {
  color: #ff7272;
  padding-top: 5px;
}
.input-auth {
  padding: 5px 10px;
  border: solid var(--brand-color-eight-text) 1px;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5px;
  width: 100%;
}
.checkbox-text-save-me {
  gap: 15px;
  padding: 5px;
  color: var(--brand-color-eight-text);
}
.checkbox-check {
  cursor: pointer;
}
.send-button {
  color: var(--brand-color-eight-text);
  cursor: pointer;
  background-color: var(--brand-color-one-button);
  border-radius: 5px;
  padding: 5px 0px;
}
.send-button:hover {
  background-color: var(--c-three-hover);
}

.send-button:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}
.recover_password {
  cursor: pointer;
}
.ili {
  color: var(--brand-color-eight-text);
  padding: 10px;
}
.send_button_auth {
  padding: 5px;
}
.text-slogan {
  color: var(--brand-color-eight-text);
  padding: 15px;
}
.recover_success {
  color: var(--brand-color-eight-text);
  padding: 10px 0px 15px 0px;
  font-size: 15px;
}
.recover_error {
  color: var(--brand-color-eight-text);
  padding: 0px 0px 20px 0px;
  font-size: 15px;
}
.authblock .block-captcha {
  padding-bottom: 10px;
}
.authblock .captcha-block-img img {
  max-width: 90px;
}
.authblock .inputValueCaptcha {
  font-size: 12px;
}

.authblock .btn-update-captcha,
.captcharesult-text {
  color: #fff;
  font-size: 12px;
  text-align: left;
}
.authblock .captcharesult-text {
  color: #ff3838;
  font-size: 16px;
}

.input-error {
  color: #ff0000;
  padding: 3px;
  font-size: 11px;
}

@media (max-width: 576px) {
  .logowhite {
    display: none;
    padding: 70px 0px 0px 0px;
  }
  .recover_password {
    padding: 15px 0px;
  }
  .authblock {
    padding: 30px 3px;
    width: 100%;
    max-width: 300px;
  }
  .input-auth {
    width: 100%;
    max-width: 300px;
  }
  .inputValueCaptcha {
    width: 100%;
  }
  .captcha-block-area {
    display: flex;
    gap: 20px;
  }
  .authblock .captcha-block-img img {
    width: 100%;
    max-width: 150px;
  }
}
