/*tabletickets*/
.tabletickets-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.taskList_item_result {
  height: calc(100% - 220px);
  overflow-x: scroll;
}
.taskList_item__scrolling_block {
  width: max-content;
  height: 100%;
}
.taskList-item-tittle {
  font-weight: 600;
}
.taskList-item-tittle .sorting-arrow {
  position: relative;
  width: max-content;
  padding-right: 15px;
}
.taskList-item-tittle .sorting-arrow:before {
  position: absolute;
  content: "↕";
  right: 0px;
  opacity: 0.5;
}
.taskList-item-tittle .active_top .sorting-arrow:before {
  position: absolute;
  content: "↓";
  opacity: 1;
}
.taskList-item-tittle .active_bottom .sorting-arrow:before {
  position: absolute;
  content: "↑";
  opacity: 1;
}
.tabletickets-grid-template {
  min-height: 50px;
  display: grid;
  grid-template-columns: 50px 50px auto 165px 150px 150px 170px 180px 200px 200px 200px;
}
.tabletickets-grid-template > div {
  align-items: center;
  display: flex;
  padding: 13px 15px;
  border-bottom: solid 1px var(--c-twelve);
}
.tabletickets-grid-template .subject {
  min-width: 300px;
  max-width: 450px;
  transition: 0.5s;
  position: relative;
}
.new_comment {
  position: relative;
}
.new_comment:before {
  content: " ";
  background: var(--brand-color-three-svg);
  border-radius: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0px;
}
.date-control {
  position: relative;
}
.data-control-area-block {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.data-control-indication {
  display: none;
  width: 100%;
}
.data-control-indication.task-overdue {
  display: flex;
}
.date-control-input-block {
  display: flex;
  width: 100%;
  position: relative;
  height: 22px;
}
.id-box svg {
  width: 19px;
  height: 19px;
}

.id-box polygon {
  fill: #ff1400;
}
.date-control-input {
  width: 100%;
}
.date-control-input input {
  width: 120px;
  border: 0px;
  font-size: 12px;
  padding-left: 1px;
  background: #0000;
}
.date-control-input-block svg {
  width: 25px;
  height: 25px;
  color: #6fc620;
}
.status_color {
  max-width: 100%;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.status_color_10 .status_color {
  color: var(--brand-color-three-text);
  background-color: var(--brand-color-four-background);
}
.status_color_950 .status_color {
  background-color: #6caa2d;
  color: #ffffff;
}
.status_color_500 .status_color {
  color: var(--brand-color-six-text);
  background-color: var(--brand-color-six-background);
}
.status_color_20 .status_color {
  color: var(--brand-color-seven-text);
  background-color: var(--brand-color-seven-background);
}
.status_color_980 .status_color {
  color: var(--brand-color-eight-text);
  background-color: var(--brand-color-eight-background);
}
.status_color_990 .status_color {
  color: var(--brand-color-eight-text);
  background-color: var(--brand-color-eight-background);
}
.data-control-area-block.red {
  color: rgb(255 255 255);
  background-color: rgb(250 90 90);
  border-radius: 5px;
}
.data-control-area-block.orange {
  color: rgb(255 255 255);
  background-color: rgb(255 169 0);
  border-radius: 5px;
}
.example-custom-input {
  padding: 2px 6px;
  font-size: 12px;
}
.selector-date-save-cancel-tableticket {
  display: flex;
  position: absolute;
  right: -40px;
  top: -2px;
  background: rgb(255 255 255);
}
.selector-date-cancel-tableticket svg {
  color: #cf0808;
}
.result-list-tasks {
  overflow-y: overlay;
  height: calc(100% - 40px);
  min-height: 400px;
}
.result-list-scrolling-block {
  height: max-content;
}
.tabletickets-result-list:nth-child(2n) {
  background: var(--brand-color-one-background);
}
.tabletickets-result {
  background-color: var(--brand-color-one-background);
}
.tabletickets-result:hover {
  background-color: var(--c-two);
}
.burger {
  transition: 0.5s;
}
.burgerButton {
  height: 20px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  width: 20px;
}
.burgermenu {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
  left: 50px;
  top: 20px;
  position: absolute;
  width: 200px;
  padding: 12px 16px;
  z-index: 1;
  display: none;
  border-radius: 10px;
}
.burger.active .burgermenu {
  display: block;
}
.middle {
  height: 1px;
  width: 20px;
  background: var(--brand-color-one-line);
  position: absolute;
  top: 10px;
}
.middle::after {
  height: 1px;
  width: 20px;
  background: var(--brand-color-one-line);
  content: " ";
  top: -5px;
  position: absolute;
  transition: 0.5s;
}
.middle::before {
  height: 1px;
  width: 20px;
  background: var(--brand-color-one-line);
  content: " ";
  top: 5px;
  position: absolute;
  transition: 0.5s;
}
.burger.active {
  position: relative;
}
.burger.active .middle::after {
  transform: rotate(45deg);
  right: 0px;
  top: -3px;
  width: 7px;
  transition: 0.5s;
}
.burger.active .middle::before {
  transform: rotate(-45deg);
  right: 0px;
  top: 3px;
  width: 7px;
  transition: 0.5s cubic-bezier(0, 3.77, 0.93, 1.38);
}
.burger-menu-item {
  border-radius: 5px;
  padding: 3px 10px;
}
.burger-menu-item:hover {
  background-color: rgb(167, 192, 229);
}

.quick-view-public-notes {
  padding: 5px;
  color: rgb(132, 167, 203);
  font-size: 12px;
  line-height: 20px;
  word-wrap: break-word;
  display: none;
  overflow: hidden;
}
.quick-view-public-notes.active {
  width: max-content;
  height: auto;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
  padding: 10px;
  z-index: 1;
  border-radius: 3px;
  left: 17px;
  top: 18px;
  background-color: rgb(248 248 248);
  display: block;
}
.task-notes-button {
  color: rgb(255, 167, 0);
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.task-notes-button.active {
  color: #009f1e;
}
.task-notes-title{
  color: #2d2d2d;
  text-transform: uppercase;
  padding-left: 8px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 13px;
}
.task-notes-blok{
  padding-bottom: 10px;
}
.task-notes-blok-comment{
  border-top: solid #dfdfdf 1px;
  padding-top: 15px;
}
.task-notes-block{
  position: absolute;
  margin-left: -3px;
}
.task-notes-items{
  
}
.subject_link{
  padding-left: 15px;
}
.task-notes-item{
  background: #fff;
    padding: 4px 7px;
    margin-bottom: 8px;
    border: solid #eaeaea 1px;
    border-radius: 6px;
}
.task-notes-item-title{
  color: rgb(10 137 211);
  font-size: 11px;
  line-height: 14px;
}
.task-notes-item-title span{
  color: rgb(175 175 175);
  display: inline-block;
  padding-left: 7px;
}


.task-notes-item-value{
  color: #000;
}

/*pagination*/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pagination-block {
  min-height: 65px;
}
.pagination-button {
  display: flex;
  gap: 15px;
}
.pagination-count-info {
  position: absolute;
  top: 25px;
  left: 20px;
  width: max-content;
}
.pagination-pagination-num {
  display: flex;
  gap: 20px;
}
.nuber-p {
  display: flex;
  cursor: context-menu;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-three-border);
  color: var(--brand-color-ten-text);
}
.pagination-num {
  display: flex;
  cursor: context-menu;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-two-border);
  color: var(--brand-color-one-text);
}
.pagination-num:hover {
  background-color: var(--brand-color-select-border);
  cursor: pointer;
}
.selecting-number-ticket {
  position: absolute;
  right: 20px;
  height: 100%;
}
.title_number_ticket_block {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 100%;
}
.title_number_ticket {
  position: absolute;
  top: 25px;
  right: 60px;
  width: max-content;
}
.cont-num-ticket-list-block {
  width: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 4px;
  border: 1px solid var(--brand-color-two-border);
  color: var(--brand-color-one-text);
  background: var(--brand-color-eight-text);
}
.cont-num-ticket-list {
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.cont-num-ticket-list.active {
  font-weight: bold;
  display: block !important;
}
.cont-num-ticket-list.show {
  display: block;
}

.taskList_item_result::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 10px; /* Высота полосы прокрутки */
}

.taskList_item_result::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}

.taskList_item_result:-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}

@media (max-width: 576px) {
  .tabletickets-title .priority,
  .tabletickets-title .users-initiator,
  .tabletickets-title .users-observer,
  .tabletickets-title .users-co-executor {
    display: none;
  }
  .tabletickets-grid-template {
    grid-template-columns: 50px 50px auto 170px 150px 150px 150px;
  }

  .tabletickets-grid-template .subject {
    min-width: 200px;
    max-width: 450px;
    width: 220px;
  }
}
