.cash_balance_icon {
  display: flex;
  align-items: center;
}
.cash_balance_icon svg {
  width: 60px;
  height: 60px;
  color: var(--c-three);
}
.itemspaymentvalue svg {
  width: 17px;
  height: 17px;
  color: var(--c-three);
}
.always-active svg {
  color: var(--c-ten);
}

.price-modules {
  position: absolute;
  right: 60px;
}
.price-modules .old_price{
  color: #a4a4a4;
  text-decoration: line-through;
  position: absolute;
  top: -10px;
  font-size: 10px;right: 15px;

}
.price-modules .new_price{

}

.total-price {
  position: absolute;
  right: 60px;
  top: 22px;
}
.right-block-balance {
  height: 100%;
}
.selector-block-balance {
  border: 1px solid var(--c-six);
  background-color: var(--c-one);
  width: max-content;
  min-width: 230px;
  border-radius: 4px;
  padding-left: 5px;
}
.btn-balance {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 10px;
  top: 12px;
}
.left-block-module {
  height: 100%;
}

.plus,
.minus {
  font-size: 17px;
}

.input-quantity_users {
  border-radius: 2px;
  outline: none;
  width: 23px;
  border: solid 1px;
  text-align: center;
}

.colorpayment1 {
  color: var(--c-fife);
}
.colorpayment5 {
  color: var(--c-six);
}
.colorpayment7 {
  color: var(--c-seven);
}
.colorpayment9 {
  color: var(--c-six);
}
.a-btn-block.access {
  background-color: var(--c-seven);
}


.demo_info{
  background: #eeffe0;
  padding: 10px 20px;
  border: solid #c4ed85 1px;
  color: #569025;
  border-radius: 8px;
}
.demo_info .demo_info_title{
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 6px;
  letter-spacing: 0.5px;
}
.demo_info  .demo_info_days{
  color: #ff1100;
  padding-top: 10px;
}