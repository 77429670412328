.content-routing {
  padding: 70px 80px 0px 210px;
}

pre {
  height: 100% !important;
  overflow: inherit;
  white-space: break-spaces;
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  word-break: break-word;
}
.color_red{
  color: #d00000;

}

.paid_href{
  padding-top: 20px;
}
.paid_href a{
  border-bottom:solid #333435 1px;
}


@media (max-width: 576px) {
  .content-routing {
    padding: 60px 0px 0px 0px;
  }
}
