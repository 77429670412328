.message-modal-block {
  background: #0000006b;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  position: fixed;
  right: 0;
  top: 60px;
  z-index: 9999;
  display: flex;
  align-items: center;
}

.message {
  align-items: center;
  background: #ffffff;
  border: 2px solid #ffffff85;
  box-shadow: 0 0 17px #000;
  display: flex;
  max-height: 50%;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px;
  position: relative;
  max-width: 50%;
  border-radius: 10px;
}

.message-icon-state {
  text-align: center;
  padding: 10px;
}
.message-icon-state svg {
  width: 50px;
  height: 50px;
}
.message-modal-close {
  position: absolute;
  right: 8px;
  top: 5px;
  cursor: pointer;
}

.message-modal-close svg {
  width: 20px;
  height: 20px;
}
.message-text-state-btn .a-btn-block {
  margin: 10px auto;
}
.message-text-state {
  padding: 20px;
}
.message-status-fail {
  color: #d06464;
}
.message-status-success {
  color: #058f10;
}
