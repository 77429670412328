.user_image {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.user_image_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  width: 125px;
  height: 125px;
  border: 1px dashed;
}
.user_image_logo img {
  width: 100%;
}
.user_image_label {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
}
.user_image_label:hover {
  opacity: 1;
}
.user_image_label svg {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}

.user-main-middle-info {
  display: flex;
  padding: 20px 0px 0px;
}

.last_activity {
  position: relative;

  position: relative;
  width: max-content;
  margin: 0 auto;
}
.last_activity:before {
  position: absolute;
  background: #ff6565;
  content: "";
  width: 6px;
  height: 6px;
  left: -10px;
  border-radius: 15px;
  top: 7px;
}
.last_activity.online:before {
  background: #28c316;
}

.user-main-item {
  padding: 10px 0px;
}
.user-main-notification {
  position: relative;
}

.user-main-right-block-items {
  padding: 15px 0px 40px 0px;
}

.user-notification-checkbox {
  position: absolute;
  top: 9px;
}
.user-main-item-notification {
  padding: 10px 0px 10px 25px;
}

.user-main-item .change-items-value {
  color: #8c8c8c;
}
.user-notification-checkbox svg {
  color: #8c8c8c;
  width: 18px;
  height: 18px;
}
.user-notification-checkbox.active svg {
  color: var(--c-three);
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.user-main-connect_telegram_block {
  padding: 20px 0px 0px;
}
.user-roles-block-items {
  padding: 20px 0px 0px;
}
.user-roles-item {
  position: relative;
}
.user_role_checkbox {
  position: absolute;
  top: 7px;
}
.user_role_checkbox svg {
  color: var(--c-three);
  width: 18px;
  height: 18px;
}
.user-roles-item-value {
  padding: 10px 0px 10px 25px;
}
.grid-userprofileconst-token {
  display: grid;
  grid-template-columns: 250px 250px 200px 120px;
  align-items: center;
}

.сurrent-device {
  background-color: var(--brand-color-ten-background);
  border-left: solid var(--brand-color-three-border) 3px;
}
.other-device {
  border-left: solid var(--c-nine) 3px;
}
