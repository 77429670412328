.buuton-bllock-ticket {
  display: flex;
  gap: 20px;

  position: absolute;
  right: 25px;
  bottom: 20px;
}
.aritineditor-area.active {
  background: #fff;
  padding: 5px 10px 20px 10px;
  min-height: 120px;
}
.aritineditor-value {
  padding-bottom: 20px;
  word-wrap: break-word;
}

.buuton-bllock-ticket label {
  color: #7e7e7e;
  cursor: pointer;
}
.functionChange-article.aritineditor-value img {
  width: 150px;
}
.btn-block-aritineditor {
  justify-content: right;
  display: flex;
}
.ticketfastcomment .arr-map-block-img {
  display: flex;
  padding-bottom: 60px;
  flex-wrap: wrap;
}
.aritin-editor-box {
  flex-wrap: wrap;
}
