.title_h1_staff_add {
  display: flex;
  gap: 20px;
  position: relative;
}

.table_staff {
  padding: 20px;
  overflow-x: scroll;
}
.staff_title_table {
  font-weight: 600;
  display: grid;
  grid-template-columns: 50px 215px 190px 105px 180px 215px 5px 50px;
  min-height: 20px;
  padding: 10px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.staff_item {
  display: grid;
  grid-template-columns: 270px 190px 105px 180px 215px 5px 50px;
  padding: 10px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.staff_item:hover {
  background-color: var(--brand-color-three-background);
}
.staff_value_image {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  position: relative;
}
.staff_value_image:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  right: -5px;
  background: #ff6565;
  border-radius: 15px;
  top: 0px;
}
.staff_value_image.online:before {
  background: #28c316;
}
.staff_value_image img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  border-radius: 50%;
}
.staff_value_items_unit {
  align-items: center;
  display: flex;
}
.staffvalue_image__name {
  display: flex;
  gap: 20px;
}

.table_staff::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 10px; /* Высота полосы прокрутки */
}

.table_staff::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-color-one-scrollbar
  ); /* Цвет полосы прокрутки */
  border-radius: 4px; /* Закругленные углы полосы прокрутки */
}

.table_staff::-webkit-scrollbar-track {
  background-color: var(
    --brand-color-two-background
  ); /* Цвет фона дорожки прокрутки */
}
