.border_background {
  border: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_right_background {
  border-right: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_left_background {
  border-left: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_bottom_background {
  border-bottom: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_bg {
  border: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}

.border_bg_r {
  border-right: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_bg_l {
  border-left: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_bg_b {
  border-bottom: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}

.border_bg_b_c_three {
  border-bottom: 1px solid var(--c-three);
  background-color: var(--brand-color-one-background);
}

.icon-title {
  cursor: pointer;
}

.class_display_none {
  display: none;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}

.change-items {
  display: flex;
  gap: 20px;
}
.icon-title {
  align-items: center;
  display: flex;
}

.cursor_no_drop {
  cursor: no-drop !important;
}
.cursor_pointer {
  cursor: pointer;
}

.title_h1 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
}
.title_h2 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
}
.title_h3 {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2;
}
.title_h4 {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.2;
}
.title_h5 {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1.2;
}

.cp {
  cursor: pointer;
}

.box_shb {
  box-shadow: 0px 3px 6px 0px #00000029;
}
.box_sht {
  box-shadow: 0px -3px 6px 0px #00000029;
}
.bgc_one {
  background-color: var(--c-one);
}
.bgc_two {
  background-color: var(--c-two);
}
.bgc_four {
  background-color: var(--c-four);
}

.tc {
  color: var(--c-one);
}

.btn_one {
  color: var(--brand-color-one-background);
  background-color: var(--brand-color-one-button);
  padding: 5px 15px;
  border-radius: 7px;
  width: max-content;
  cursor: pointer;
}

.btn_line {
  border-bottom: solid 1px var(--brand-color-one-button);
  cursor: pointer;
  width: max-content;
  color: var(--brand-color-eleven-text);
}

.b_b_bg {
  border-bottom: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}

.br_r_5 {
  border-radius: 5px;
}
.d_flex_g20 {
  display: flex;
  gap: 20px;
}
.h_maxc {
  height: max-content;
}
.fs_13 {
  font-size: 13px;
}

/*********************************
*********************************
*********************************
*************New*****************
*********************************
*********************************
*********************************/

/*********************************
**************Text**************
*********************************/

.a-text-1 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
}
.a-text-2 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--c-eleven);
}
.a-text-3 {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2;
}
.a-text-4 {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.2;
  color: var(--c-eleven);
}
.a-text-5 {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1.2;
}
.a-text-4-400 {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.2;
}
.a-text-5-400 {
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1.2;
}

.a-text--c-six {
  color: var(--c-six);
}
.a-text--c-three {
  color: var(--c-three);
}
.a-fw-600 {
  font-weight: 600;
}

/*********************************
********Display - Center**********
*********************************/

.a-flex {
  display: flex;
}

.a-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.a-center {
  justify-content: center;
  align-items: center;
}
.a-ai-center {
  align-items: center;
}
.a-flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}
.a-flex-align-center {
  align-items: center;
  display: flex;
}
.a-gap-20 {
  gap: 20px;
}
.a-gap-10 {
  gap: 10px;
}
.a-gap-5 {
  gap: 5px;
}

/*********************************
**************Border**************
*********************************/
.a-solid {
  border: solid;
}
.a-w-max-content {
  width: max-content;
}
.a-border-bg {
  border: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}

.a-border-bg-r {
  border-right: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.a-border-bg-l {
  border-left: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.a-border-bg-b {
  border-bottom: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.a-border-bg-t {
  border-top: 1px solid var(--brand-color-one-border);
  background-color: var(--brand-color-one-background);
}
.border_bg_b_c_nine {
  border-bottom: 1px solid var(--c-nine);
  background-color: var(--c-one);
}

.a-radius-5 {
  border-radius: 5px;
}
.a-radius-5-5 {
  border-radius: 5px 5px 0 0;
}

.a-border-bg-b--c-three {
  border-bottom: 1px solid var(--c-three);
  background-color: var(--brand-color-one-background);
}
.a-border-bg-b--c-nine-light {
  border-bottom: 1px solid var(--c-nine-light);
  background-color: var(--brand-color-one-background);
}
.a-border-bg--c-three {
  border: 1px solid var(--c-three);
  background-color: var(--brand-color-one-background);
}

/*********************************
*************Pozition*************
*********************************/

.a-section-title {
  padding-bottom: 10px;
  padding-left: 10px;
}

.a-relative {
  position: relative;
}

.a-btn-block {
  color: var(--c-one);
  background-color: var(--c-three);
  padding: 5px 15px;
  border-radius: 7px;
  width: max-content;
  cursor: pointer;
}
.a-btn-block:hover {
  background-color: var(--c-three-hover);
}

.a-btn-block:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}

.a-btn-block-bottom-left {
  color: var(--c-one);
  background-color: var(--c-three);
  padding: 5px 15px;
  border-radius: 7px;
  width: max-content;
  cursor: pointer;
}
.a-btn-block-bottom-left:hover {
  background-color: var(--c-three-hover);
}

.a-btn-block-bottom-left:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}

.a-pointer {
  cursor: pointer;
}

.a-btn_block_add {
  position: absolute;
  right: 5px;
  top: 0px;
  color: var(--brand-color-one-background);
  background-color: var(--c-three);
  padding: 5px 15px;
  border-radius: 7px;
  cursor: pointer;
}

.a-btn_block_add:hover {
  background-color: var(--c-three-hover);
}

.a-btn_block_add:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}

.a-height-100 {
  height: 100%;
}

.a-margin-auto {
  margin: 0 auto;
}

.a-btn-block-cansel {
  background: #e8e8e8;
  color: #3a3a3a;
}
.a-btn-block-save {
  background: #2983fd !important;
  color: #fff;
}

.a-btn-block-cansel,
.a-btn-block-save {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

/*********************************
*************Link*************
*********************************/

.a-link a {
  color: #195ec0;
}

.a-link a:hover {
  text-decoration: underline;
  color: #3387fe;
}

.fitrash-bottom-right {
  position: absolute;
  right: 5px;
  bottom: 10px;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;
  border: 1px solid rgba(226, 226, 226, 0.7);
  color: var(--brand-color-three-svg);
  border-radius: 4px;
  width: max-content;
}
.fitrash-bottom-right:hover {
  background-color: rgb(226, 226, 226, 0.7);
}

.block_delete_fitrash {
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  padding: 0px 10px;
  vertical-align: middle;
  border: 1px solid rgba(226, 226, 226, 0.7);
  color: var(--brand-color-three-svg);
  border-radius: 4px;
  width: max-content;
  height: 100%;
}
.block_delete_fitrash:hover {
  background-color: rgb(226, 226, 226, 0.7);
}

.block_delete_fitrash_no_border {
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  padding: 0px 10px;
  vertical-align: middle;
  color: var(--brand-color-three-svg);
  width: max-content;
  height: 100%;
}
.block_delete_fitrash_no_border:hover {
  background-color: rgb(226, 226, 226, 0.7);
}
