.counterpartie_contacts .a-btn-block {
  position: absolute;
  right: 0px;
  top: 0px;
}
.counterpartie_contacts .change_password.a-btn-block {
  color: var(--c-one);
  background-color: var(--c-three);
  padding: 5px 15px;
  border-radius: 7px;
  width: max-content;
  cursor: pointer;
  position: relative;
}
.password-btn-block {
  display: flex;
  gap: 10px;
}

.counterpartie_contacts_item_grid {
  display: grid;
  grid-template-columns: 90px auto;
  align-items: center;
}
.a-text-3 .change-items-value {
  font-weight: 600;
  font-size: 0.9rem !important;
  line-height: 1.2;
  color: var(--c-eleven) !important;
}
