.gallery_images_block {
  position: fixed;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #0000006b;
  z-index: 9999;

  padding: 20px 0px;
}
.gallery_images_content {
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  background: #000;
  box-shadow: 0px 0px 17px #000;
  border: solid #ffffff85 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.gallery_images_content img {
  max-height: 100%;
  max-width: 100%;
}
.gallery_images_onclick {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 90px;
  background: #0000007d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.gallery_images_onclick svg {
  width: 70px;
  height: 70px;
  color: #a2a1a1;
  transition: 0.3s;
}
.gallery_images_onclick:hover {
  background: #2f2f2f7d;
}
.gallery_images_onclick:hover svg {
  color: #fff;
}

.gallery_images_onclick.next {
  right: -50px;
  border-radius: 0px 40px 40px 0px;
}
.gallery_images_onclick.prev {
  left: -50px;
  border-radius: 40px 0px 0px 40px;
}
.gallery_images_btn_close {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 5px;
  right: 5px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gallery_images_btn_close svg {
  color: #fff;
  width: 30px;
  height: 30px;
  box-shadow: 0px 0px 10px #000;
  background: #00000096;
}
