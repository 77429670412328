.confirm_password_all_content {
  justify-items: center;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  background-size: cover !important;
  padding-top: 70px;
}
.confirm_password {
  padding: 0px 230px;
}
.confirm_password_left_right {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.confirm_password_left {
  padding: 15px;
  height: 300px;
  width: 60%;
}
.confirm_password_right {
  padding: 15px 15px 20px;
  width: 40%;
  position: relative;
}
.confirm_password_title_block {
  padding: 15px;
}
.confirm_password_title_area {
  padding: 0px 0px 20px 0px;
}
.confirm_password_input {
  border: 1px solid rgb(230, 235, 241);
  cursor: text;
  border-radius: 4px;
  margin: 10px 0px;
}
.input_password_no_auth {
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  width: calc(100% - 10px);
  padding: 10px 0px 10px 10px;
}
.confirm_password_send_button {
  display: flex;
  justify-content: end;
}
.change_password_no_auth {
  background-color: var(--brand-color-one-button);
  border-radius: 5px;
  padding: 7px 15px;
  color: var(--brand-color-eight-text);
  width: max-content;
  cursor: pointer;
}
.confirm_password_logoblack_text_slogan {
  display: flex;
  gap: 20px;
}
.confirm_password_logoblack_logo img {
  width: 250px;
}
.confirm_password_logoblack_made_in_rf img {
  width: 250px;
}
.confirm_password_description_text {
  padding: 25px 0px;
}
.working {
  padding: 0px 15px 15px;
}
.btn_enter {
  padding: 15px;
}

.confirm_password_text {
  font-size: 10px;
  padding: 0px 3px;
  color: #008000;
}
