.notes-block {
  margin-bottom: 40px;
}

.notes-block-left,
.notes-block-right {
  width: 50%;
  padding: 5px;
  position: relative;
}
.notes-block-title {
  padding: 10px 0px 0px 10px;
  color: #258ad4;
}

.notes-button {
  cursor: pointer;
  width: max-content;
  color: rgb(132 132 132);
  font-size: 12px;
  border-bottom: 0px;
  transition: 0.3s;
  position: absolute;
  right: 10px;
  bottom: -20px;
}

.notes-button:hover {
  color: #258ad4;
}

.notes-button-delete {
  position: absolute;
  right: 130px;
  bottom: 10px;
  font-size: 12px;
  color: rgb(132 132 132);
  cursor: pointer;
}
.notes-button-delete:hover {
  color: #258ad4;
}

.notes-block-left .input-ticket-box-text {
  background-color: #def;
}

.notes-block-left .aritineditor-value {
  font-size: 16px;
}

.notes-block-right .input-ticket-box-text {
  background-color: #f2edc8;
}

.notes-block-right .aritineditor-value {
  font-size: 16px;
}

.preloader-download {
  display: block;
  position: relative;
  width: 90px;
  height: 70px;
  margin: 30px auto;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #337ab7;
  animation: preloader-download-spin 2s linear infinite;
}
.preloader-download:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #bfe2ff;
  animation: preloader-download-spin 3s linear infinite;
}
.preloader-download:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #337ab7;
  animation: preloader-download-spin 1.5s linear infinite;
}
@keyframes preloader-download-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
