.template-grid-template {
  display: grid;
  grid-template-columns: 50px 500px;
  border-bottom: solid 1px var(--brand-color-one-border);
}
.template-grid-template > div {
  align-items: center;
  display: flex;
  padding: 13px 15px;
}

.template-grid-template:hover {
  background-color: var(--brand-color-three-background);
}
