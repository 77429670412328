.functionChange {
  font-size: 13px;
  color: #858585;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  border: solid #fff 1px;
  position: relative;
  min-height: 26px;
}

.functionChange:hover {
  color: #000;
}
.functionChange.active {
  border: solid #e6e5e5 1px;
  color: #000;
  margin-left: -10px;
  padding-left: 10px;
  border-bottom: solid #fff 1px;
  z-index: 99;
}
.functionChange-article {
  cursor: text;
}

.functionChange-article:focus {
  outline: 0px;
}
.functionChange-button {
  position: absolute;
  background: #fff;
  z-index: 2;
  bottom: -42px;
  left: -1px;
  right: -1px;
  display: flex;
  gap: 5px;
  border: solid #e6e5e5 1px;
  padding-left: 10px;
  padding-top: 5px;
  border-top: solid #fff 1px;
  padding-bottom: 10px;
  border-radius: 0px 0px 5px 5px;
}
.functionChange-save,
.functionChange-cansel {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-weight: 500;
}
.functionChange-cansel {
  background: #e8e8e8;
  color: #3a3a3a;
}
.functionChange-save {
  background: #2983fd;
  color: #fff;
}

.functionChangeSelector {
  font-size: 13px;
  color: #858585;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 5px;
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  border: solid #fff 1px;
  position: relative;
  cursor: pointer;
  min-height: 26px;
}
.functionChangeSelector-list {
  border: solid #e6e5e5 1px;
  color: #000;
  margin-left: -10px;
  min-width: 190px;
  z-index: 99;
  position: absolute;
  margin-top: -5px;
  border-radius: 5px;
  padding-top: 4px;
  background: #fff;
}
.functionChangeSelector-placeHolder {
  color: #b5b4b4;
  width: max-content;
  padding: 0px 9px 5px 9px;
}
.functionChangeSelector-items {
  border-bottom: solid #f3f3f3 1px;
  padding: 8px 10px;
}
.functionChangeSelector-items:hover {
  background: #f4f4f4;
}
.functionChangeSelector-items:last-child {
  border-bottom: solid #f3f3f3 0px;
}
.functionChange-placeholder {
  position: absolute;
  top: 4px;
  color: #c8c8c8;
  cursor: text;
}
