.counterpartie_branch .a-btn-block {
  position: absolute;
  right: 0px;
  top: 0px;
}
.counterpartie_branch_item_grid {
  display: grid;
  grid-template-columns: 130px auto;
  align-items: center;
}

.a-text-2 .change-items-value {
  font-weight: 600;
  font-size: 1.1rem !important;
  line-height: 1.2;
  color: var(--c-eleven) !important;
}
