@media (max-width: 576px) {
  .App-header {
    background-color: #282a36;
  }
  .mobile-filter {
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 13px;
    right: 50px;
    width: 25px;
    z-index: 55;
  }
  .mobile-menu {
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu-text {
    font-size: 9px;
  }
  .mobile-menu-block {
    display: flex;
    gap: 10px;
    padding: 2px 20px 20px;
  }

  /********** Chat **********/
  .chat {
    display: none;
    top: 50px;
    bottom: 80px;
  }
  .chat.active-mobile {
    display: block;
  }
  .item-mobile-icon-text {
    height: 50px;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .item-mobile-icon svg {
    display: flex;
    height: 30px;
    width: 40px;
  }
  .chat_right {
    height: 100%;
    bottom: 0px;
    display: none;
  }
  .chat_right.active {
    display: block;
  }
  .chat_right_top {
    padding-top: 10px;
  }
  .chat_active .chat_center_area {
    left: 0px;
    height: 100%;
    width: 100%;
  }
  .chat_left {
    display: none;
  }

  /********** Menu **********/
  .sidebar_menu {
    height: 0px;
    width: 0px;
  }
  .sidebar_menu.active {
    height: calc(100% - 80px);
    width: 100%;
    transition: 0.7s;
    background-color: #282a36;
  }
  .sub_section_icon__sub_section_text_title {
    color: var(--c-one);
  }
  .menu_section {
    color: var(--c-one);
  }
  .app_logo_block {
    display: none;
  }
  .section_title__section_menu.none {
    display: none;
  }
  .mobile-burger {
    height: 1px;
    width: 25px;
    background: rgb(0, 0, 0);
    position: relative;
  }
  .mobile-burger::before {
    height: 1px;
    width: 25px;
    background: rgb(0, 0, 0);
    content: " ";
    top: 5px;
    left: 0px;
    position: absolute;
    transition: 0.5s;
  }
  .mobile-burger::after {
    height: 1px;
    width: 25px;
    background: rgb(0, 0, 0);
    content: " ";
    top: -5px;
    left: 0px;
    position: absolute;
    transition: 0.5s;
  }
  .mobile-menu-panel.active .mobile-burger {
    height: 1px;
    width: 20px;
    background: rgb(0, 0, 0);
    position: absolute;
    z-index: 500;
    transform: rotate(-45deg);
  }
  .mobile-menu-panel.active .mobile-burger::before {
    transform: rotate(-90deg);
    right: 10px;
    top: 0px;
    width: 20px;
    transition: 0.5s cubic-bezier(0, 3.77, 0.93, 1.38);
  }
  .mobile-menu-panel.active .mobile-burger::after {
    display: none;
  }
  .mobile {
    height: 20px;
    line-height: 18px;
    position: relative;
    cursor: pointer;
    width: 20px;
  }
  .mobile-menu-panel {
    display: flex;
    height: 50px;
    width: 50px;
    align-items: center;
    padding: 10px;
  }
  .sidebar_menu {
    top: 0px;
    z-index: 500;
  }
  .item-mobile-icon {
    justify-content: center;
    display: flex;
  }

  /********** contact_support **********/
  .support-block-top {
    padding-bottom: 1rem;
  }
}
