.App-header {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  box-shadow: 0px 10px 100px #dce4f185;
  background-color: #282a36f7;
  min-height: 60px;
  display: flex;
  font-size: calc(10px + 2vmin);
  width: 100%;
  height: 50px;
}
.header_contetnt {
  width: 100%;
}
.logo_link_img {
  padding: 5px 10px;
  color: var(--c-one);
  height: 100%;
}
.logo_link_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo_block {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}
.user_block {
  color: #fff;
  font-size: 11px;
}
.date_block {
  position: relative;
}
.date_block_time {
  position: absolute;
  top: 7px;
  right: 20px;
  font-size: 18px;
}
.date_block_date {
  color: #aaaaaa;
  font-size: 14px;
  position: absolute;
  bottom: 7px;
  right: 20px;
}

.user_name_avatar {
  display: flex;
  gap: 10px;
  background-color: #1c1e29;
  padding: 17px 20px;
  justify-content: center;
  width: max-content;
}
.login_name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_block img {
  max-width: 100%;
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 45px;
  box-shadow: 0px 0px 11px #00000029;
}

.logout {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.logo-name-text {
  font-size: 17px;
  text-align: center;
}

@media (max-width: 576px) {
  .logo_link_img img {
    height: 30px;
    object-fit: fill;
    width: 90px;
  }
  .date_block {
    display: none;
  }

  .App-header {
    min-height: 40px;
  }
  .user_name_avatar {
    padding: 8px 10px;
    width: 65%;
  }
  .col-none {
    display: none;
  }

  .mobile-filter-panel svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
  .user_block {
    width: 100%;
  }
}
