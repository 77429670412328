.settings-top-block {
  display: grid;
  grid-template-columns: 25% 25% 30% 20%;
  align-items: center;
}

.settings-top-block-logo {
  width: max-content;
  height: max-content;
}
.settings-top-block-logo img {
  max-width: 170px;
  max-height: 60px;
}

.settings-top-block_image_label {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
  cursor: pointer;
}
.settings-top-block_image_label svg {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.settings-top-block_image_label:hover {
  opacity: 1;
}
.settings-top-block_name .change-items-value {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--c-eleven);
}

.settings-top-block_url .change-items-value {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--c-eleven);
}
.settings-midle-right-items {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.setting-block .change-items-value {
  color: var(--c-six);
}
.settings-bottom-right-items {
  align-items: center;
}
.settings-iochevronforwardsharp {
  display: inline-flex;
  align-items: center;
  height: 25px;
}
.settings-iochevronforwardsharp svg {
  width: 15px;
  height: 10px;
}

.setting_menu_block {
  height: 100%;
}

.setting_menu_item {
  line-height: 35px;
  height: 35px;
  cursor: pointer;
  color: #000000;
}

.setting_menu_item.active {
  color: #2983fd;
  font-weight: bold;
}

.setting_right_area-title {
  padding-bottom: 20px;
  color: #2e2e2e !important;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}
.setting_right_area-item:first-child {
  border-top: solid #eaeaea 0px;
}
.setting_right_area-item {
  border-top: solid #eaeaea 1px;
  padding: 15px;
}
.setting_right_area-group {
  background: #fbfbfb;
  padding: 20px;
  margin-bottom: 30px;
  border: solid #f1f1f1 1px;
}
.setting_right_area-group-title {
  font-size: 14px;
  color: #000;
  font-weight: bold;
  padding-bottom: 20px;
}
.setting_right_area-item-name {
  font-weight: bold;
}

.setting_right_area-group .functionChange {
  background: #fbfbfb;
  border: solid #fbfbfb 1px;
}
.setting_right_area-group .functionChange-article {
  color: #181818;
}

.setting_right_area-group .functionChange.active {
  background: #fff;
  min-width: 120px;
}

.setting-status_group {
  padding: 20px;
  border: 1px solid #b3b3b3;
  flex-basis: calc(50% - 40px);
  border-radius: 10px;
  background: rgb(236, 244, 255);
}
.setting-status_group-title {
  padding-bottom: 5px;
  color: #000;
  display: flex;
  align-items: center;
}
.setting-status_group-items {
  padding-left: 5px;
}
.setting-status_group-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.setting-status_group-item .functionChange-article {
  cursor: pointer;
}
.setting-status_group-title .functionChange-article {
  cursor: pointer;
}

.setting_right_area_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px;
  justify-content: center;
}

.setting-status_group-item .functionChange {
  background: #ecf4ff;
  border: solid #ecf4ff 1px;
}
.setting-status_group-title .functionChange {
  background: #ecf4ff;
  border: solid #ecf4ff 1px;
  font-weight: 600;
  font-size: 14px;
}
.service-stutus .functionChange-article {
  color: #8d8d8d;
  cursor: no-drop !important;
}
.setting_right_area-button_area {
  padding-top: 25px;
}
.setting-status_group.drop-highlighting {
  border: 2px dashed #5b5b5b;
}
.setting-status_group-title.service-group .functionChange-article {
  cursor: no-drop !important;
}
