.knowledge-categories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 10px 0px;
}
.knowledge-categories-fitrash {
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  padding: 3px;
  vertical-align: middle;
  /* border: 1px solid rgba(226, 226, 226, 0.7); */
  color: var(--brand-color-three-svg);
  /* border-radius: 4px; */
  width: max-content;
  height: 100%;
}
.knowledge-categories-title .functionChange {
  font-size: 13px;
  color: #000;
  line-height: 1.5;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.article-item-title {
  color: #535353;
  padding: 3px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-style: italic;
}

.knowledge-without-categories-article li {
  color: #7a7a7a;
  padding: 3px 0px;
}
.knowledge-without-categories-title {
  font-size: 13px;
  color: #000;
  padding: 15px 0px 10px 0px;
  line-height: 1.5;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.knowledge-categories-article {
  border: 1px dashed #ffffff;
}
.knowledge-categories-article.drop-article {
  border: 1px dashed #5b5b5b;
}

.article-block {
  border-bottom: 1px solid #cacaca;
  margin: 0px 0px 20px 0px;
}
.article-title-block .functionChange {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.article-title-block .functionChange.active .functionChange-button {
  font-size: 12px;
}
.article-info-block {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 20px 0px 10px 0px;
}
.article-info-items {
  display: flex;
  gap: 10px;
}
.article-info-value {
  font-size: 12px;
  color: #717171;
}
.article-info-item-del {
  color: #db0000;
}
.article-aritin-editor {
  position: relative;
}
.knowledge-categories-article,
.knowledge-without-categories {
  border-bottom: 1px solid #d9d9d9;
  margin: 10px 0px;
}
.add-block-knowledge-art-cat {
  position: relative;
  display: flex;
  gap: 10px;
  padding: 10px 0px;
}

.btn_block_add-knowledge {
  color: var(--brand-color-one-background);
  background-color: var(--c-three);
  padding: 5px 10px;
  border-radius: 7px;
  cursor: pointer;
}
.knowledge-categories-area {
  position: sticky;
  top: 70px;
}
